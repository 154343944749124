import React, { useContext, useState, useEffect } from 'react'
import Button from '../../../../Static/Button';
import { myContext } from '../../../../../Context';
import axios from 'axios';
import pfp from '../../../../../assets/images/Default_pfp.svg.png';
import { VscLoading } from 'react-icons/vsc';

function TrainerEditVideo({ editVideoModal, seteditVideoModal, token, formData, setFormData, editVideo }) {
    const [categories, setCategories] = useState([]);
    const { themeMode } = useContext(myContext);
    const [selectedImage, setSelectedImage] = useState(null);
    const fileInputRef = React.useRef(null);
    const [loading, setLoading] = useState(false);

    const handleImageDeselect = () => {
        setSelectedImage(null);
        setFormData({ ...formData, thumbnail: null });
        fileInputRef.current.value = null;
    };

    const getCategories = async () => {
        try {
            const res = await axios.get(process.env.REACT_APP_ENDPOINT + '/video/getCategories', {
                withCredentials: true,
                headers: {
                    ContentType: 'application/json',
                },
            });
            if (res.status === 200) {
                setCategories(res.data.categories);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const getVideo = async () => {
        setLoading(true);
        try {
            const res = await axios.get(`${process.env.REACT_APP_ENDPOINT}/video/getVideo/${editVideoModal.id}`, {
                withCredentials: true,
                headers: {
                    ContentType: 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.status === 200) {
                setFormData(res.data.video);
                setLoading(false);
            }
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    }

    useEffect(() => {
        getCategories();
        getVideo();
    }, [])

    const cancelTokenSource = axios.CancelToken.source();

    const handleCancel = () => {
        cancelTokenSource.cancel('Operation canceled by the user.');
        seteditVideoModal(false);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    }

    return (
        <div className='fixed bg-[rgba(0,0,0,0.5)] w-full top-0 left-0 h-full backdrop-blur-sm z-40'>
            <div className='flex justify-center items-center h-full'>
                <div className={`transition-all duration-300 ease-in-out py-6 px-10 rounded-xl ${themeMode === 'dark' ? 'bg-[rgb(50,50,50)]' : 'bg-slate-50'} `}>
                    <h2 className='font-bold text-3xl mb-5'>Update Video</h2>
                    <form className='flex flex-col gap-3 w-full' encType='multipart'>
                        {
                            loading ? <div className={`${themeMode === "light" ? "bg-white" : "bg-[rgb(50,50,50)]"} h-[500px] w-[500px] overflow-hidden`}>
                                <p className="flex justify-center items-center text-orange-500 h-full animate-spin text-[4rem]"><VscLoading /></p>
                            </div> :
                                <>
                                    <div className='flex justify-between'>
                                        <div className='flex flex-col gap-2'>
                                            <label htmlFor='title' className='text-xl'>Title</label>
                                            <input type='text' name='title' id='title' value={formData.title}
                                                onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                                                className={`py-2 border px-5 rounded-xl ${themeMode === 'dark' ? 'bg-[rgb(50,50,50)] text-white' : 'bg-slate-50'}`} />
                                        </div>
                                        <div className='flex flex-col gap-2'>
                                            <label htmlFor='description' className='text-xl'>Description</label>
                                            <textarea name='description' id='description' value={formData.description}
                                                onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                                                className={`py-2 border px-5 w-[400px] rounded-xl ${themeMode === 'dark' ? 'bg-[rgb(50,50,50)] text-white' : 'bg-slate-50'}`} />
                                        </div>
                                    </div>

                                    <label htmlFor='trainer' className='text-xl flex items-center gap-2'>Uploaded by:
                                        <div className='flex justify-start items-center gap-2'>
                                            <img src={formData.trainer.profilePhoto ? formData.trainer.profilePhoto : pfp} alt='trainer' className='w-10 h-10 rounded-full' />
                                            {formData.trainer.firstName} {formData.trainer.lastName}
                                        </div>
                                    </label>

                                    <div className='flex justify-between items-center gap-6 my-2'>
                                        <div className='flex gap-2'>
                                            <label htmlFor='categories' className='text-xl'>Category</label>
                                            <select name='categories' id='categories' value={formData.categories}
                                                onChange={(e) => setFormData({ ...formData, categories: e.target.value })}
                                                className={`py-1 border px-3 rounded-xl ${themeMode === 'dark' ? 'bg-[rgb(50,50,50)] text-white' : 'bg-slate-50'}`}>
                                                <option value=''>Select a category</option>
                                                {categories.map((category, index) => (
                                                    <option key={index} value={category}>{category}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className='flex w-full justify-between'>
                                            <label className="block text-lg font-bold mr-4" htmlFor="isQuickLink">
                                                Serve as Quick Link?
                                            </label>
                                            <button
                                                type='button'
                                                className={`w-16 h-8 rounded-full transition-all ease-in-out duration-300 p-1 ${formData.isQuickLink ? 'bg-[rgb(224,92,19)]' : 'bg-gray-500'
                                                    }`}
                                                onClick={() =>
                                                    handleInputChange({
                                                        target: { name: 'isQuickLink', value: !formData.isQuickLink },
                                                    })
                                                }>
                                                <div
                                                    className={`w-7 h-6 rounded-full shadow-md transition-all z-10 ease-in-out duration-300 ${formData.isQuickLink ? 'transform translate-x-7' : ''
                                                        } bg-white border-2 border-gray-300`}
                                                ></div>
                                            </button>
                                        </div>
                                    </div>

                                    <label>*Note: Do not upload new video or thumbnail if you don't wish to change.</label>
                                    <div className='flex flex-col gap-5'>
                                        <input type="file" placeholder='video' name='video' id='video' className='px-5 py-2 rounded-xl bg-orange-500'
                                            onChange={(e) => setFormData({ ...formData, video: e.target.files[0] })} accept="video/*" />
                                    </div>
                                    <div className='flex flex-col gap-5'>
                                        <label
                                            className={`transition-all ease-in-out duration-300 
                                            w-full h-10 py-5 mt-5 flex justify-center items-center rounded-2xl border-2 border-dashed 
                                            hover:rounded-xl cursor-pointer ${selectedImage ? 'hidden' : ''
                                                }`}
                                            htmlFor="thumbnail"
                                        >
                                            <p className="text-lg">+ Upload new thumbnail</p>
                                        </label>
                                        <input type='file' name='thumbnail' id='thumbnail' onChange={(e) => {
                                            setFormData({ ...formData, thumbnail: e.target.files[0] });
                                            setSelectedImage(URL.createObjectURL(e.target.files[0]));
                                        }} className={`hidden`} ref={fileInputRef} />
                                    </div>
                                    {selectedImage && (
                                        <div className='flex justify-between items-center'>
                                            <img src={selectedImage} alt="Thumbnail" className='w-[250px] rounded-2xl' />
                                            <Button text='Deselect Image' onClick={handleImageDeselect} className='px-5 py-2 rounded-xl bg-red-500 text-white' />
                                        </div>
                                    )}
                                    <div className='flex gap-5 justify-end'>
                                        {loading === true ? <p className={`transition-all ease-in-out duration-300 font-semibold 
                            text-green-500 p-3 rounded-2xl ${themeMode === 'dark' ? 'bg-green-950' : 'bg-green-200'}`}>Uploading please wait...</p>
                                            :
                                            <Button text='Update' onClick={() => editVideo(editVideoModal)} className='px-5 py-2 rounded-xl bg-green-500 text-white' />
                                        }
                                        <Button text='Cancel' onClick={handleCancel} className='px-5 py-2 rounded-xl bg-red-500 text-white' />
                                    </div>
                                </>
                        }
                    </form>
                </div>
            </div>
        </div>
    )
}

export default TrainerEditVideo