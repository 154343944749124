import React, { useEffect, useState, useContext } from 'react';
import Header from '../../Static/Header/Header';
import ManageBanner from './DashItems/BannerSection/ManageBanner';
import Profile from './DashItems/Profile/Profile';
import axios from 'axios';
import { myContext } from '../../../Context';
import TrainersList from './DashItems/TrainersList/TrainersList';
import TrainerProfile from './DashItems/TrainersList/TrainerProfile';
import ProductList from './DashItems/Products/ProductList';
import VideoList from './DashItems/Videos/VideoList';
import toast from 'react-hot-toast';
import ProductDetails from './DashItems/Products/ProductDetails';

function SideNavbar() {
    const { themeMode } = useContext(myContext);

    const token = localStorage.getItem("jwtToken");
    const [active, setActive] = useState('');
    const [userType, setUserType] = useState('');
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        _id: '',
        type: '',
        permissions: '',
        profilePhoto: '',
        address: '',
        category: '',
        subcategory: '',
        gender: '',
        dob: '',
    })

    const getUserDetails = async () => {
        try {
            const res = await axios.get(process.env.REACT_APP_ENDPOINT + '/auth/user', {
                withCredentials: true,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.status === 200) {
                setFormData(res.data.sendDetails);
                if (res.data.sendDetails.type === 'main') {
                    setUserType('main');
                }
            }
        } catch (error) {
            toast.error('Session Expired! Please Login Again');
            localStorage.removeItem('jwtToken');
            localStorage.removeItem('jwtTokenExpiration');
            window.location.reload();
            console.error(error);
        }
    };

    useEffect(() => {
        getUserDetails();
        if (userType !== 'main') {
            setActive('Profile');
        } else {
            setActive(localStorage.getItem('active'));
        }
    }, [userType]);

    const handleItemClick = (item, adminId) => {
        if (adminId) {
            localStorage.setItem('adminId', adminId);
        }
        setActive(item);
        localStorage.setItem('active', item);
    };

    const sidebarItemClass = (itemName) =>
        `text-lg rounded-xl mb-5 px-5 p-3 cursor-pointer transition-all duration-300 ${active === itemName
            ? 'bg-[rgb(224,92,19)] text-white opacity-100'
            : 'opacity-50'
        }`;

    return (
        <>
            <Header handleItemClick={handleItemClick} profile={formData.firstName} />
            <div className="flex flex-col justify-center items-start h-full">
                <div className='flex flex-col w-full h-full items-center'>
                    <div className={`transition-all duration-300 ease-in-out fixed top-[6.51rem] left-0 w-[250px] h-full z-20 shadow-xl ${themeMode === 'dark' ? 'bg-[rgb(24,26,27)] text-white' : 'bg-white'}`}>
                        {userType === 'main' ?
                            <ul className="py-5 px-3">
                                <li
                                    className={sidebarItemClass('Banner')}
                                    onClick={() => handleItemClick('Banner')}
                                >
                                    Banners
                                </li>
                                <li
                                    className={sidebarItemClass('Trainers List')}
                                    onClick={() => handleItemClick('Trainers List')}
                                >
                                    Trainers List
                                </li>
                                <li
                                    className={sidebarItemClass('Video List')}
                                    onClick={() => handleItemClick('Video List')}
                                >
                                    Video List
                                </li>
                                <li
                                    className={sidebarItemClass('Products')}
                                    onClick={() => handleItemClick('Products')}
                                >
                                    Products
                                </li>
                                <li
                                    className={sidebarItemClass('Profile')}
                                    onClick={() => handleItemClick('Profile')}
                                >
                                    Profile
                                </li>
                                {/* <li
                                    className={sidebarItemClass('Admins')}
                                    onClick={() => handleItemClick('Admins')}
                                >
                                    Admin Accounts
                                </li> */}

                            </ul>
                            :
                            <ul className="py-5 px-3">
                                <li
                                    className={sidebarItemClass('Profile')}
                                    onClick={() => handleItemClick('Profile')}
                                >
                                    Profile
                                </li>
                            </ul>
                        }
                    </div>

                    <div className={`transition-all duration-300 ease-in-out flex flex-col justify-start items-start rounded w-full h-full py-10 pl-64 ${themeMode === 'dark' ? 'bg-slate-950' : 'bg-slate-200'}`}>
                        {active === 'Profile' ? <Profile token={token}
                            getUserDetails={getUserDetails}
                            handleItemClick={handleItemClick}
                            formData={formData}
                            setFormData={setFormData}
                        /> : null}
                        {active === 'Banner' ? <ManageBanner setActive={setActive} token={token} /> : null}
                        {/* {active === 'Admins' ? <AdminAccounts token={token} handleItemClick={handleItemClick} /> : null}
                        {active === 'Admin Profile' ? <AdminProfile token={token} handleItemClick={handleItemClick} /> : null} */}
                        {active === 'Trainers List' ? <TrainersList token={token} handleItemClick={handleItemClick} /> : null}
                        {active === 'Trainer Profile' ? <TrainerProfile token={token} handleItemClick={handleItemClick} /> : null}
                        {active === 'Video List' ? <VideoList token={token} handleItemClick={handleItemClick} /> : null}
                        {active === 'Products' ? <ProductList token={token} handleItemClick={handleItemClick} /> : null}
                        {active === 'Product Details' ? <ProductDetails token={token} handleItemClick={handleItemClick} /> : null}
                    </div>
                </div>
            </div>
        </>

    );
}

export default SideNavbar;
