import React, { useContext, useState, useEffect } from 'react'
import Button from '../../../../Static/Button'
import Pagination from '../../../../Static/Pagination'
import { FaPlay } from 'react-icons/fa'
import { myContext } from '../../../../../Context'
import axios from 'axios'
import toast from 'react-hot-toast'
import EditVideoForm from './EditVideoForm'
import { MdEdit, MdDeleteForever } from 'react-icons/md'
import pfp from '../../../../../assets/images/Default_pfp.svg.png'
import { VscLoading } from 'react-icons/vsc'

function ApprovedVideos({ token }) {
    const { themeMode } = useContext(myContext)
    const [currentPage, setCurrentPage] = useState(1)
    const [selectedVideo, setSelectedVideo] = useState(null)
    const [confirmModal, setConfirmModal] = useState({ show: false, id: null })
    const [editModal, setEditModal] = useState({ showModal: false, id: null });
    const [videos, setVideos] = useState([])
    const [loading, setLoading] = useState(false)
    const videosPerPage = 10

    const pageNumbers = []
    for (let i = 1; i <= Math.ceil(videos.length / videosPerPage); i++) {
        pageNumbers.push(i)
    }

    const indexOfLastVideo = currentPage * videosPerPage
    const indexOfFirstVideo = indexOfLastVideo - videosPerPage
    const currentVideos = videos.slice(indexOfFirstVideo, indexOfLastVideo)

    const playVideo = (videoURL) => {
        setSelectedVideo(videoURL)
    }

    const getApprovedVideos = async () => {
        setLoading(true)
        try {
            const res = await axios.get(`${process.env.REACT_APP_ENDPOINT}/video/approvedVideos`, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.status === 200) {
                const newVideos = res.data.videos;
                setVideos(newVideos);

                const newPageNumbers = Math.ceil(newVideos.length / videosPerPage);
                if (currentPage > newPageNumbers) {
                    setCurrentPage(newPageNumbers);
                }
                setLoading(false)
            }
        } catch (error) {
            console.error(error);
            setLoading(false)
        }
    };

    useEffect(() => {
        getApprovedVideos()
    }, [])

    const disapproveVideo = async (id) => {
        try {
            const res = await axios.put(`${process.env.REACT_APP_ENDPOINT}/video/disapproveVideo/${id}`, {}, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.status === 200) {
                toast.success('Video Disapproved!');
                getApprovedVideos();
            }
        } catch (error) {
            console.error(error);
        }
    }

    const deleteVideo = async (videoId, trainerId) => {
        setLoading(true)
        try {
            const res = await axios.delete(
                `${process.env.REACT_APP_ENDPOINT}/video/deleteVideo`,
                {
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    data: {
                        videoId: videoId,
                        trainerId: trainerId,
                    },
                }
            );

            if (res.status === 200) {
                toast.success('Video Deleted!');
                setConfirmModal(false)
                setLoading(false)
                getApprovedVideos();
            }
        }
        catch (error) {
            console.error(error);
            setLoading(false)
        }
    }

    const handleEditModal = (id) => {
        setEditModal({ showModal: true, id });
    }

    return (
        <div className='flex flex-col w-full justify-start px-3 gap-10'>
            {
                loading === true ? <div className={`${themeMode === "light" ? "bg-white" : "bg-[rgb(50,50,50)]"} h-screen w-full overflow-hidden`}>
                    <p className="flex justify-center items-center text-orange-500 h-full animate-spin text-[4rem]"><VscLoading /></p>
                </div> :
                    currentVideos.map((video, index) => (
                        video.approved ? (
                            <>
                                <div key={index} className={`flex gap-10 items-start border-b border-gray-400 pb-5`}>
                                    {selectedVideo === video.videoURL ? (
                                        <video
                                            className='rounded-2xl w-[250px] cursor-pointer'
                                            src={video.videoURL}
                                            controls
                                        />
                                    ) : (
                                        <div
                                            className='rounded-2xl w-[530px] max-h-[150px] overflow-hidden cursor-pointer relative'
                                            onClick={() => playVideo(video.videoURL)}
                                        >
                                            <div className='absolute z-10 w-full flex justify-center items-center h-full'>
                                                <FaPlay className=' text-white text-5xl' />
                                            </div>
                                            <div className='relative'>
                                                <img className='w-full h-full rounded-xl' src={video.thumbnailURL} alt="Thumbnail" />
                                            </div>
                                        </div>
                                    )}
                                    <div className='flex justify-between items-start w-full'>
                                        <div className='flex flex-col gap-1 w-2/3'>
                                            <p className='text-xl font-bold'>{video.title}</p>
                                            <div className='flex gap-2 items-center my-2'>
                                                <img src={video.trainer.profilePhoto !== '' ? video.trainer.profilePhoto : pfp} alt="Trainer" className='w-10 h-10 rounded-full' />
                                                <p className='font-bold'>{video.trainer.firstName} {video.trainer.lastName}</p>
                                            </div>
                                            <p className=''>{video.categories}</p>
                                            <p className='text-md overflow-hidden overflow-ellipsis max-w-[300px] whitespace-normal break-words'>
                                                {video.description}
                                            </p>
                                        </div>
                                    </div>
                                    <div className='flex gap-3 justify-end'>
                                        <Button text='Disapprove' onClick={() => disapproveVideo(video._id)} className='px-5 py-2 rounded-xl bg-green-500 text-white' />
                                        <Button icon={<MdEdit />} onClick={() => handleEditModal(video._id)} className='px-5 py-2 rounded-xl bg-blue-500 text-white' />
                                        <Button icon={<MdDeleteForever />} onClick={() => setConfirmModal({ show: true, id: video._id })} className='px-5 py-2 rounded-xl bg-red-500 text-white' />
                                    </div>
                                    {video.approved.length <= 0 && <p className='text-lg'>No videos to show</p>}
                                </div>
                                {confirmModal.show && confirmModal.id === video._id && (
                                    <div className='fixed bg-[rgba(0,0,0,0.5)] w-full top-0 left-0 h-full backdrop-blur-sm z-50'>
                                        <div className='flex justify-center items-center h-full'>
                                            <div className={`py-5 px-10 rounded-xl ${themeMode === 'dark' ? 'bg-[rgb(50,50,50)]' : 'bg-slate-50'} `}>
                                                <h2 className='font-bold text-2xl mb-5'>Confirm delete?</h2>
                                                <h3 className='text-lg'>This action cannot be undone, are you sure?</h3>
                                                <div className='flex gap-5 mt-10 justify-end'>
                                                    {
                                                        loading === true ? (
                                                            <>
                                                                <Button disabled={true} text={"Please wait..."} />
                                                                <Button disabled={true} text={"Cancel"} />
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Button onClick={() => deleteVideo(video._id, video.trainer._id)} text={"Confirm"} />
                                                                <Button onClick={() => setConfirmModal({ show: false, id: '' })} text={"Cancel"} />
                                                            </>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </>
                        ) : null
                    ))}
            {videos.length !== 0 &&
                < div className='flex justify-end gap-5 items-center'>
                    Showing {indexOfFirstVideo + 1}-{indexOfLastVideo > videos.length ? videos.length : indexOfLastVideo} of {videos.length} videos
                    <Pagination
                        pageNumbers={pageNumbers}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                    />
                </div>

            }
            {
                editModal.showModal === true && <EditVideoForm editModal={editModal} setEditModal={setEditModal} token={token} getVideoss={getApprovedVideos} />
            }
            {videos.length === 0 && <p className='text-lg text-center p-10'>There are no approved videos to show</p>}
        </div>
    )
}

export default ApprovedVideos