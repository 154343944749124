import React from 'react'
import { myContext } from '../../../../../Context'
import axios from 'axios'
import Button from '../../../../Static/Button';
import { MdDelete, MdEdit, MdSearch } from 'react-icons/md';
import ProductForm from './ProductForm';
import { toast } from 'react-hot-toast';
import { VscLoading } from 'react-icons/vsc';
import Pagination from '../../../../Static/Pagination';

export default function ProductList({ token, handleItemClick }) {
    const { themeMode } = React.useContext(myContext);
    const [products, setProducts] = React.useState([])
    const [addMode, setAddMode] = React.useState(false);
    const [formData, setFormData] = React.useState({
        name: '',
        description: '',
        price: 0,
        discountedPrice: 0,
        quantity: 0,
        category: '',
        subcategory: '',
        isActive: '',
    });
    const [loading, setLoading] = React.useState(false);
    const fileInputRef = React.useRef();
    const [selectedFiles, setSelectedFiles] = React.useState([]);
    const [editMode, setEditMode] = React.useState({ show: false, id: '' });
    const [confirmModal, setConfirmModal] = React.useState({ show: false, id: '' });
    const [filteredProducts, setFilteredProducts] = React.useState([]);
    const [searchTerm, setSearchTerm] = React.useState('');
    const [currentPage, setCurrentPage] = React.useState(1);
    const [productsPerPage] = React.useState(10);

    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(filteredProducts.length / productsPerPage); i++) {
        pageNumbers.push(i);
    }

    const handleImageChange = (e) => {
        setFormData({ ...formData, images: e.target.files });
        const files = e.target.files;
        const fileNames = Array.from(files).map((file) => file.name);
        setSelectedFiles(fileNames);
    };

    const getProducts = async () => {
        setLoading(true);
        try {
            const res = await axios.get(process.env.REACT_APP_ENDPOINT + '/shop/products', {
                withCredentials: true,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.status === 200) {
                setProducts(res.data);
                setFilteredProducts(res.data);
                setLoading(false);
            }
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    }

    React.useEffect(() => {
        getProducts();
    }, [])

    const handleSearch = (searchTerm) => {
        if (products.length === 0) {
            setFilteredProducts([]);
            return;
        }

        const filtered = products.filter((product) =>
            product.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredProducts(filtered);
    };


    const handleViewProduct = (id) => {
        localStorage.setItem('productId', id);
        handleItemClick('Product Details');
    };

    const addProduct = async (e) => {
        e.preventDefault();

        if (formData.name === '') return toast.error('Product name is required');
        if (formData.description === '') return toast.error('Product description is required');
        if (formData.price === '') return toast.error('Product price is required');
        if (formData.discountedPrice === '') return toast.error('Product discounted price is required');
        if (formData.quantity === '') return toast.error('Product quantity is required');
        if (formData.category === '') return toast.error('Product category is required');
        if (formData.subcategory === '') return toast.error('Product subcategory is required');
        if (formData.images === undefined) return toast.error('Product images are required');

        setLoading(true);
        const NewFormData = new FormData();
        NewFormData.append('name', formData.name);
        NewFormData.append('description', formData.description);
        NewFormData.append('price', formData.price);
        NewFormData.append('discountedPrice', formData.discountedPrice);
        NewFormData.append('quantity', formData.quantity);
        NewFormData.append('category', formData.category);
        NewFormData.append('subcategory', formData.subcategory);
        NewFormData.append('isActive', formData.isActive);
        for (let i = 0; i < formData.images.length; i++) {
            NewFormData.append('images', formData.images[i]);
        }

        try {
            const res = await axios.post(process.env.REACT_APP_ENDPOINT + '/shop/addProduct', NewFormData, {
                withCredentials: true,
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.status === 201) {
                toast.success('Product Added Successfully');
                setFormData({});
                setAddMode(false);
                setSelectedFiles([]);
                fileInputRef.current.value = '';
                getProducts();
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    }

    const handleEditClick = async (id) => {
        try {
            const res = await axios.get(process.env.REACT_APP_ENDPOINT + '/shop/product/' + id, {
                withCredentials: true,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.status === 200) {
                setFormData(res.data);
                setEditMode({ show: true, id: id });
            }
        } catch (error) {
            console.error(error);
        }
    }

    const handleEdit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const newFormData = new FormData();
        newFormData.append('name', formData.name);
        newFormData.append('description', formData.description);
        newFormData.append('price', formData.price);
        newFormData.append('discountedPrice', formData.discountedPrice);
        newFormData.append('quantity', formData.quantity);
        newFormData.append('category', formData.category);
        newFormData.append('subcategory', formData.subcategory);
        newFormData.append('isActive', formData.isActive);
        for (let i = 0; i < formData.images.length; i++) {
            newFormData.append('images', formData.images[i]);
        }
        try {
            const res = await axios.put(process.env.REACT_APP_ENDPOINT + '/shop/editProduct/' + editMode.id, newFormData, {
                withCredentials: true,
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.status === 200) {
                toast.success('Product Updated Successfully');
                setEditMode({ show: false, id: '' });
                setFormData({});
                setLoading(false);
                getProducts();
            }
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    }

    const handleDelete = async (id) => {
        setLoading(true);
        try {
            const res = await axios.delete(process.env.REACT_APP_ENDPOINT + '/shop/deleteProduct/' + id, {
                withCredentials: true,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.status === 200) {
                toast.success('Product Deleted Successfully');
                setConfirmModal(false);
                getProducts();
                setLoading(false);
            }
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    }

    return (
        <div className='w-full h-screen py-24 px-7'>
            {
                loading === true ? <div className={`${themeMode === "light" ? "bg-white" : "bg-[rgb(50,50,50)]"} h-screen w-full overflow-hidden`}>
                    <p className="flex justify-center items-center text-orange-500 h-full animate-spin text-[4rem]"><VscLoading /></p>
                </div> :
                    <div className={`transition-all ease-in-out duration-300 py-10 text-lg rounded-2xl ${themeMode === 'dark' ? 'bg-[rgb(30,30,30)] text-white' : 'bg-slate-50'} text-black`}>
                        <div className='flex w-full justify-between px-10 mb-5'>
                            <h1 className='text-2xl font-bold'>Products</h1>
                            <div className='flex gap-5 items-center'>
                                <input
                                    type="text"
                                    placeholder="Search products"
                                    value={searchTerm}
                                    onChange={(e) => {
                                        setSearchTerm(e.target.value);
                                        handleSearch(e.target.value);
                                    }}
                                    className='pl-10 py-2 border bg-transparent relative border-gray-400 rounded-2xl'
                                />
                                <MdSearch className='text-2xl ml-3 absolute' />
                                <Button text='Add Product' onClick={() => setAddMode(!addMode)} />
                            </div>
                        </div>
                        <table className="table-auto w-full">
                            <thead>
                                <tr className={`duration-300 text-xl ${themeMode === 'dark' ? 'bg-[rgb(50,50,50)]' : 'bg-slate-200'}`}>
                                    <th className='text-start px-10 py-2'>Product Name</th>
                                    <th className='text-start px-4 py-2'>Price</th>
                                    <th className='text-start px-4 py-2'>Quantity</th>
                                    <th className='text-start px-4 py-2 opacity-0'>Stock</th>
                                    <th className='text-start px-4 py-2 opacity-0'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredProducts && filteredProducts.length > 0 ? currentProducts.map((product, index) => (
                                    <tr className={`border-b-2 ${themeMode === 'dark' ? 'hover:bg-[rgb(90,90,90)]' : 'hover:bg-[rgb(230,230,230)]'}`} key={index}>
                                        <td className='px-10 py-5 cursor-pointer' onClick={() => handleViewProduct(product._id)}>
                                            <div className='flex gap-2'>
                                                <img src={product.images[0].url} alt={product.name} className='w-20 h-20 mr-2 rounded-2xl shadow-lg' />
                                                <div className='flex flex-col gap-2'>
                                                    <p>{product.name}</p>
                                                    <p className='opacity-70'>{product.category}</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td className='px-5 py-5 cursor-pointer' onClick={() => handleViewProduct(product._id)}>{product.price}</td>
                                        <td className='px-5 py-5 cursor-pointer' onClick={() => handleViewProduct(product._id)}>{product.quantity}</td>
                                        <td className='px-5 py-5 cursor-pointer text-end' onClick={() => handleViewProduct(product._id)}>{product.isActive ? 'Active' : 'Inactive'}</td>
                                        <td className='py-5 flex gap-5 pr-10 pt-8 justify-end'>
                                            <Button icon={<MdEdit />} onClick={() => handleEditClick(product._id)} />
                                            <Button icon={<MdDelete />} onClick={() => setConfirmModal({ show: true, id: product._id })} />
                                        </td>
                                        {confirmModal.id === product._id && confirmModal.show === true && (
                                            <div className='fixed bg-[rgba(0,0,0,0.4)] w-full top-0 left-0 h-full backdrop-blur-sm z-50'>
                                                <div className='flex justify-center items-center h-full'>
                                                    <div className={`py-5 px-10 rounded-xl ${themeMode === 'dark' ? 'bg-[rgb(50,50,50)]' : 'bg-slate-50'} `}>
                                                        <h2 className='font-bold text-2xl mb-5'>Confirm delete?</h2>
                                                        <h3 className='text-lg'>This action cannot be undone, are you sure?</h3>
                                                        <div className='flex gap-5 mt-10 justify-end'>
                                                            {
                                                                loading === true ? (
                                                                    <>
                                                                        <Button disabled={true} text={"Please wait..."} />
                                                                        <Button disabled={true} text={"Cancel"} />
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <Button onClick={() => handleDelete(product._id)} text={"Confirm"} />
                                                                        <Button onClick={() => setConfirmModal({ show: false, id: '' })} text={"Cancel"} />
                                                                    </>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </tr>
                                )) : (
                                    <p className='text-xl mt-10 pl-20'>No products found.</p>
                                )}
                            </tbody>
                        </table>
                        {
                            <div className='flex justify-end gap-5 mt-7 pr-10 text-center'>
                                Showing {indexOfFirstProduct + 1}-{Math.min(indexOfLastProduct, filteredProducts.length)} of {filteredProducts.length} products
                                <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} pageNumbers={pageNumbers} />
                            </div>
                        }
                    </div>
            }
            {
                addMode && <ProductForm token={token} addMode={addMode} setConfirmModal={setAddMode}
                    formData={formData} setFormData={setFormData} handleSubmit={addProduct} loading={loading}
                    fileInputRef={fileInputRef} handleImageChange={handleImageChange} selectedFiles={selectedFiles}
                    text={'Add Product'}
                />
            }
            {
                editMode.show && <ProductForm token={token} addMode={addMode} setConfirmModal={setEditMode}
                    formData={formData} setFormData={setFormData} handleSubmit={handleEdit} loading={loading}
                    fileInputRef={fileInputRef} handleImageChange={handleImageChange} selectedFiles={selectedFiles}
                    text={'Edit Product'}
                />
            }
        </div>
    )
}

