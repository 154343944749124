import React from 'react'
import { Document, Page, pdfjs } from 'react-pdf';
import Button from '../../../../Static/Button';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


export default function CertModal(
    {
        isModalOpen,
        closeModal,
        selectedCertificate,
        setSelectedCertificate,
        numPages,
        setNumPages,
        pageNumber,
        setPageNumber,
        onDocumentLoadSuccess
    }
) {
    return (
        <div>{isModalOpen && (
            <div className='fixed top-0 left-0 w-full h-screen z-50 overflow-auto bg-black bg-opacity-50'>
                <div className='flex justify-center mt-12'>
                    <div className='relative'>
                        <div className='absolute right-3 top-5 z-50 text-sm'>
                            <Button
                                text='X'
                                onClick={closeModal}
                            />
                        </div>
                        {selectedCertificate && (
                            <div>
                                <Document file={`${process.env.REACT_APP_ENDPOINT}${selectedCertificate}`} onLoadSuccess={onDocumentLoadSuccess}>
                                    <Page pageNumber={pageNumber} />
                                </Document>
                                <p className='absolute text-black bottom-16 right-5'>
                                    Page {pageNumber} of {numPages}
                                </p>
                                <div className='flex justify-between mt-2'>
                                    <Button
                                        text='<'
                                        onClick={() => {
                                            const newPageNumber = pageNumber - 1;
                                            if (newPageNumber >= 1) {
                                                setPageNumber(newPageNumber);
                                            }
                                        }}
                                        disabled={pageNumber <= 1}
                                    />

                                    <Button
                                        text='>'
                                        onClick={() => {
                                            const newPageNumber = pageNumber + 1;
                                            if (newPageNumber <= numPages) {
                                                setPageNumber(newPageNumber);
                                            }
                                        }}
                                        disabled={pageNumber >= numPages}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )}</div>
    )
}
