import React, { useContext, useState, useEffect } from 'react';
import { myContext } from '../../../../../Context';
import Button from '../../../../Static/Button';
import axios from 'axios';
import AddVideoTrainer from './AddVideoTrainer';
import Pagination from '../../../../Static/Pagination';
import { FaPlay } from "react-icons/fa";
import { MdEdit, MdDeleteForever } from "react-icons/md";
import TrainerEditVideo from '../TrainersList/TrainerEditVideo';
import { toast } from 'react-hot-toast';
import './css.css'

function ProfileVideos({ token }) {
  const { themeMode } = useContext(myContext);
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [deleteModal, setDeleteModal] = useState({ show: false, id: '' });
  const [editVideoModal, seteditVideoModal] = useState({ show: false, id: '' });
  const [videoData, setvideoData] = useState({
    title: '',
    description: '',
    categories: '',
    quickLink: '',
    thumbnail: null,
    video: null,
    trainer: {},
  });
  const [profile, setProfile] = useState('');
  const videosPerPage = 7;

  const getVideos = async () => {
    try {
      const res = await axios.get(process.env.REACT_APP_ENDPOINT + '/video/myVideos', {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.status === 200) {
        setVideos(res.data.videos);
        setProfile(res.data.trainer._id)
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getVideos();
  }, []);

  const indexOfLastVideo = currentPage * videosPerPage;
  const indexOfFirstVideo = indexOfLastVideo - videosPerPage;
  const currentVideos = videos.slice(indexOfFirstVideo, indexOfLastVideo);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(videos.length / videosPerPage); i++) {
    pageNumbers.push(i);
  }

  const playVideo = (videoURL) => {
    setSelectedVideo(videoURL);
  };

  const deleteVideo = async (id, profile) => {
    try {
      const res = await axios.delete(`${process.env.REACT_APP_ENDPOINT}/video/deleteVideo`, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data: {
          videoId: id,
          trainerId: profile,
        },
      });
      if (res.status === 200) {
        setDeleteModal({ show: false, id: profile });
        getVideos();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const editVideo = async () => {
    setLoading(true);

    try {
      const formDataObj = new FormData();
      formDataObj.append('title', videoData.title);
      formDataObj.append('description', videoData.description);
      formDataObj.append('trainer', videoData.trainer);
      formDataObj.append('categories', videoData.categories);
      formDataObj.append('isQuickLink', videoData.quickLink);
      formDataObj.append('video', videoData.video);
      formDataObj.append('thumbnail', videoData.thumbnail);

      const res = await axios.put(
        `${process.env.REACT_APP_ENDPOINT}/video/editVideo/${editVideoModal.id}`,
        formDataObj,
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (res.status === 200) {
        toast.success('Video Uploaded!');
        getVideos();
        seteditVideoModal(false);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className={`w-full py-10 px-5 h-full`}>
      <div className={`transition-all ease-in-out duration-300 px-9 py-7 shadow-xl rounded-2xl ${themeMode === 'dark' ? 'bg-[rgb(50,50,50)] text-white' : 'bg-slate-100'}`}>
        <div className='flex w-full justify-end'>
          <Button text={'Upload Video'} onClick={() => setOpenModal(!openModal)} />
        </div>
        <div className='flex flex-col w-full justify-start mt-5 gap-10'>
          {currentVideos.length > 0 ?
            currentVideos.map((video, index) => (
              <div key={index} className='flex gap-10 my-5 items-start w-full'>
                {selectedVideo === video.videoURL ? (
                  <video
                    className='rounded-2xl w-[400px] cursor-pointer'
                    src={video.videoURL}
                    controls
                    width={400}
                  />
                ) : (
                  <div
                    className='rounded-2xl max-w-[300px] cursor-pointer relative'
                    onClick={() => playVideo(video.videoURL)}
                  >
                    <div className='absolute z-10 w-full flex justify-center items-center h-full'>
                      <FaPlay className=' text-gray-300 text-5xl' />
                    </div>
                    <div className='relative'>
                      <img className='w-full h-full' src={video.thumbnailURL} alt="Thumbnail" />
                    </div>
                  </div>
                )}
                <div className='flex justify-between items-start w-full'>
                  <div className='flex flex-col gap-1 w-2/3'>
                    <p className='text-2xl font-bold'>{video.title}</p>
                    <p className='text-lg'>{video.categories}</p>
                    <p className='text-md overflow-hidden overflow-ellipsis max-w-[600px] whitespace-normal break-words'>
                      {video.description}
                    </p>
                  </div>
                  <div className='flex flex-col gap-1'>
                    <p className='text-lg'>
                      {video.approved === true ? 'Approved' : 'Pending..'}
                    </p>
                    <p className='text-lg'>Views: {video.views}</p>
                  </div>
                  <div className='flex gap-3'>
                    <div className='text-2xl'>
                      <Button text={<MdEdit />} onClick={() => seteditVideoModal(
                        {
                          show: true,
                          id: video._id,
                        }
                      )} />
                    </div>
                    <Button icon={<MdDeleteForever />} onClick={() => setDeleteModal({ show: true, id: '' })} />
                  </div>
                </div>
                {deleteModal.show === true && (
                  <div className='fixed bg-[rgba(0,0,0,0.5)] w-full top-0 left-0 h-full backdrop-blur-sm z-50'>
                    <div className='flex justify-center items-center h-full'>
                      <div className={`transition-all ease-in-out duration-300 py-5 px-10 rounded-xl ${themeMode === 'dark' ? 'bg-[rgb(50,50,50)] text-white' : 'bg-slate-50 text-black'} `}>
                        <h2 className='font-bold text-2xl mb-5'>Confirm delete?</h2>
                        <h3 className='text-lg'>This action cannot be undone, are you sure?</h3>
                        <div className='flex gap-5 mt-10 justify-end'>
                          <Button text='Yes' onClick={() => deleteVideo(video._id, profile)} />
                          <Button text='No' onClick={() => setDeleteModal({ show: false, profile })} />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )) : <p className='text-center text-xl py-10'>No videos found</p>}
          <Pagination pageNumbers={pageNumbers} currentPage={currentPage} setCurrentPage={setCurrentPage} />
          {editVideoModal.show === true && <TrainerEditVideo editVideoModal={editVideoModal} seteditVideoModal={seteditVideoModal}
            loading={loading} setLoading={setLoading}
            editVideo={editVideo} token={token}
            formData={videoData} setFormData={setvideoData}
          />}
        </div>
      </div>
      {openModal === true && <AddVideoTrainer token={token} getVideos={getVideos} setOpenModal={setOpenModal} />}
    </div>
  );
}

export default ProfileVideos;
