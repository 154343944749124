import React, { useEffect, useState, useContext } from 'react'
import Button from '../../../../Static/Button'
import axios from 'axios'
import toast from 'react-hot-toast'
import { MdClose, MdAdd } from 'react-icons/md';
import { myContext } from '../../../../../Context';

function CreateForm({ token, getBanners, setCreateMode }) {
    const [selectedImage, setSelectedImage] = useState(null);
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        bannerType: '',
        isActive: false,
        bannerImage: null,
        link: '',
    });
    const fileInputRef = React.useRef(null);
    const { themeMode } = useContext(myContext);
    const [loading, setLoading] = useState(false);

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : value;

        setFormData((prevData) => ({
            ...prevData,
            [name]: newValue,
        }));
    };

    const handleImageDeselect = () => {
        setSelectedImage(null);
        setFormData((prevData) => ({
            ...prevData,
            bannerImage: null,
        }));
        fileInputRef.current.value = null;
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];

        setFormData((prevData) => ({
            ...prevData,
            bannerImage: file,
        }));
        setSelectedImage(file);
    };

    const onCreate = async (e) => {
        e.preventDefault();

        if (formData.title === "") return toast.error("Please enter a title.");
        if (formData.description === "") return toast.error("Please enter a description.");
        if (formData.bannerType === "") return toast.error("Please select a banner type.");
        if (formData.bannerImage === null) return toast.error("Please select a banner image.");
        
        setLoading(true);
        try {
            const requestData = new FormData();
            requestData.append('title', formData.title);
            requestData.append('description', formData.description);
            requestData.append('bannerType', formData.bannerType);
            requestData.append('link', formData.link);
            requestData.append('isActive', formData.isActive);
            requestData.append('bannerImage', formData.bannerImage);

            const response = await axios.post(
                process.env.REACT_APP_ENDPOINT + '/banner/createBanner',
                requestData,
                {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            toast.success('Banner created successfully');
            setCreateMode(false);
            setLoading(false);
            setSelectedImage(null);
            setFormData({});
            getBanners();
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    return (
        <div className='fixed bg-[rgba(0,0,0,0.5)] w-full top-0 left-0 h-full backdrop-blur-sm z-40'>
            <div className='flex justify-center items-center h-full'>
                <div className={`flex px-10 w-[800px] py-5 shadow-2xl rounded-2xl ${themeMode === 'dark' ? 'bg-[rgb(50,50,50)] text-white' : 'bg-slate-100'}`}>
                    <form onSubmit={onCreate} className='w-full'>
                        <div className='flex justify-between'>
                            <div className='flex flex-col w-[400px]'>
                                <div className="mb-2">
                                    <label className="block font-bold text-base mb-2" htmlFor="title">
                                        Title*
                                    </label>
                                    <input
                                        className="shadow text-base appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline text-black"
                                        id="title"
                                        type="text"
                                        onChange={handleInputChange}
                                        name="title"
                                    />
                                </div>
                                <div className="mb-2">
                                    <label className="block text-base font-bold mb-2" htmlFor="description">
                                        Description*
                                    </label>
                                    <textarea
                                        className="shadow h-20 text-base appearance-none border py-2 px-3 rounded w-full  leading-tight focus:outline-none focus:shadow-outline text-black"
                                        id="description"
                                        onChange={handleInputChange}
                                        name="description"
                                    />
                                </div>
                                <div className="mb-2">
                                    <label className="block text-base font-bold mb-2" htmlFor="bannerType">
                                        Banner Type*
                                    </label>
                                    <select
                                        className="shadow appearance-none border text-base rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline text-black"
                                        id="bannerType"
                                        name="bannerType"
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Select Banner Type</option>
                                        <option value="shop">Shop</option>
                                        <option value="video">Video</option>
                                        <option value="explore">Explore</option>
                                        <option value="link">Link</option>
                                    </select>
                                </div>
                                <div className="mb-2">
                                    <label className="block font-bold text-base mb-2" htmlFor="title">
                                        Link
                                    </label>
                                    <input
                                        className="shadow text-base appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline text-black"
                                        id="link"
                                        type="text"
                                        onChange={handleInputChange}
                                        name="link"
                                    />
                                </div>
                                <div className="mb-2">
                                    <label className="block text-base font-bold mb-2" htmlFor="isActive">
                                        Make it active?
                                    </label>
                                    <button
                                        type='button'
                                        className={`w-16 h-8 rounded-full transition-all ease-in-out duration-300 p-1 ${formData.isActive ? 'bg-[rgb(224,92,19)]' : 'bg-gray-500'
                                            }`}
                                        onClick={() =>
                                            handleInputChange({
                                                target: { name: 'isActive', value: !formData.isActive },
                                            })
                                        }
                                    >
                                        <div
                                            className={`w-7 h-6 rounded-full shadow-md transition-all ease-in-out duration-300 ${formData.isActive ? 'transform translate-x-7' : ''
                                                } bg-white border-2 border-gray-300`}
                                        ></div>
                                    </button>
                                </div>
                            </div>
                            <div className='flex flex-col gap-10 max-w-[290px] mt-7'>
                                <div className='flex flex-col w-[290px]'>
                                    <label className="block text-base font-bold mb-2" htmlFor="bannerImage">
                                        New banner image*
                                    </label>
                                    {selectedImage ?
                                        <></> :
                                        <label
                                            className={`transition-all ease-in-out duration-300 
                                            w-full h-10 py-5 mt-5 flex justify-center items-center rounded-2xl border-2 border-dashed hover:rounded-xl cursor-pointer ${selectedImage ? 'hidden' : ''
                                                }`}
                                            htmlFor="bannerImage"
                                        >
                                            <p className="text-lg">Select new image</p>
                                        </label>
                                    }
                                    <input
                                        type="file"
                                        accept="image/*"
                                        className='hidden'
                                        onChange={handleImageChange}
                                        name="bannerImage"
                                        id="bannerImage"
                                        ref={fileInputRef}
                                    />
                                </div>
                                {selectedImage && (
                                    <div className='relative'>
                                        <div className='cursor-pointer absolute right-0 px-2 shadow-xl bg-red-500 transition-all ease-in-out duration-300 text-2xl hover:scale-105 text-white rounded-full'
                                            onClick={handleImageDeselect}
                                        >
                                            X                                        </div>
                                        <img
                                            src={URL.createObjectURL(selectedImage)}
                                            alt="Selected"
                                            className='w-full max-h-[300px]'
                                        />
                                        <p className='mt-4 font-semibold text-center'>Selected Image: <br />{selectedImage.name}</p>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="flex items-center justify-end pb-2">
                            <div className="flex gap-2">
                                {loading === true ? (
                                    <>
                                        <Button disabled={true} text={"Please wait..."} />
                                        <Button text={"Cancel"} disabled={true} />
                                    </>
                                ) : (
                                    <>
                                        <Button icon={<MdAdd />} text={"Add Banner"} typeButton={"submit"} />
                                        <Button text={"Cancel"} onClick={() => setCreateMode(false)} />
                                    </>
                                )
                                }
                            </div>
                        </div>
                    </form>
                </div>
            </div >
        </div >
    );
}

export default CreateForm