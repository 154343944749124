import React, { useContext } from 'react'
import { myContext } from '../../Context'

function Button({ text, typeButton, onClick, icon, disabled }) {
    const { themeMode } = useContext(myContext)
    return (
        <button
            className={`transition-all ease-in-out duration-300 bg-[rgb(224,92,19)] shadow-lg
            flex justify-center items-center gap-2
              hover:bg-[rgb(230,120,19)] text-white font-bold py-3 px-4 rounded-xl focus:outline-none focus:shadow-outline 
             ${themeMode === 'dark' ? ' shadow-orange-900 hover:shadow-orange-800' : ' shadow-orange-200 hover:shadow-orange-300'}`}
            type={typeButton || 'button'}
            onClick={onClick || null}
            disabled={disabled || false}
        >
            {icon ?
                <span className='text-2xl'>
                    {icon}
                </span> :
                null}
            {text}
        </button>
    )
}

export default Button