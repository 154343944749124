import React, { useContext, useEffect } from 'react'
import { myContext } from '../../Context'
import { IoIosSunny } from "react-icons/io";
import { FaMoon } from "react-icons/fa6";

export default function ThemeToggler() {
    const { themeMode, toggleTheme } = useContext(myContext)

    return (
        <div className='flex items-center'>
            <div className='transition-all ease-in-out duration-500 z-50 w-6 h-7'>
                {themeMode === 'dark' ? <FaMoon className='w-6 h-7 text-white rotate-[-30deg] absolute' /> : <IoIosSunny className='w-7 h-7 text-black absolute' />}
                <input
                    className='w-10 h-10 opacity-0 absolute cursor-pointer'
                    type="checkbox"
                    id="theme"
                    checked={themeMode === "dark" ? true : false}
                    onChange={toggleTheme}
                />
            </div>
        </div>
    )
}
