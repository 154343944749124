import React, { useContext, useState, useEffect } from 'react'
import Button from '../../../../Static/Button';
import { myContext } from '../../../../../Context';
import axios from 'axios';
import TrainerSearchModal from './TrainerSearchModal'
import toast from 'react-hot-toast';

function AddVideoForm({ setOpenModal, token, setCurrentTab }) {
    const [categories, setCategories] = useState([]);
    const { themeMode } = useContext(myContext);
    const [trainers, setTrainers] = useState([]);
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        trainer: '',
        categories: '',
        quickLink: '',
        video: null,
        thumbnail: null,
    });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const fileInputRef = React.useRef(null);

    const handleImageDeselect = () => {
        setSelectedImage(null);
        setFormData({ ...formData, thumbnail: null });
        fileInputRef.current.value = null;
    };


    const handleTrainerSelect = (selectedTrainer) => {
        setFormData({ ...formData, trainer: selectedTrainer._id });
        setIsModalOpen(false);
    };

    const getCategories = async () => {
        try {
            const res = await axios.get(process.env.REACT_APP_ENDPOINT + '/video/getCategories', {
                withCredentials: true,
                headers: {
                    ContentType: 'application/json',
                },
            });
            if (res.status === 200) {
                setCategories(res.data.categories);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : value;

        setFormData((prevData) => ({
            ...prevData,
            [name]: newValue,
        }));
    };

    const handleVideoChange = (e) => {
        const { name, files } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: files[0],
        }));
    };

    const handleThumbnailChange = (e) => {
        const { name, files } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: files[0],
        }));
        setSelectedImage(files[0]);
    };

    const getTrainers = async () => {
        try {
            const res = await axios.get(process.env.REACT_APP_ENDPOINT + '/auth/getTrainers', {
                withCredentials: true,
                headers: {
                    ContentType: 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.status === 200) {
                setTrainers(res.data.trainers);
            }
        }
        catch (error) {
            console.error(error);
        }
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const cancelTokenSource = axios.CancelToken.source();

    useEffect(() => {
        getCategories();
        getTrainers();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formData.title) return toast.error('Title is required!');
        if (!formData.description) return toast.error('Description is required!');
        if (!formData.trainer) return toast.error('Trainer is required!');
        if (formData.categories === '') return toast.error('Category is required!');
        if (!formData.video) return toast.error('Video is required!');
        if (!formData.thumbnail) return toast.error('Thumbnail is required!');

        setLoading(true);
        try {
            const formDataObj = new FormData();
            formDataObj.append('title', formData.title);
            formDataObj.append('description', formData.description);
            formDataObj.append('trainer', formData.trainer);
            formDataObj.append('categories', formData.categories);
            formDataObj.append('quickLink', formData.quickLink);
            formDataObj.append('video', formData.video);
            formDataObj.append('thumbnail', formData.thumbnail);

            const res = await axios.post(
                `${process.env.REACT_APP_ENDPOINT}/video/uploadVideoAdmin`,
                formDataObj,
                {
                    withCredentials: true,
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                    cancelToken: cancelTokenSource.token,
                }
            );

            if (res.status === 200) {
                toast.success('Video Uploaded!');
                setCurrentTab('Approved')
                setCurrentTab('Unapproved')
                setOpenModal(false);
                setFormData({});
                setLoading(false);
            }
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled:', error.message);
            } else {
                setLoading(false);
                console.error(error);
            }
        }
    };

    const handleCancel = () => {
        cancelTokenSource.cancel('Operation canceled by the user.');
        setOpenModal(false);
        setFormData({});
    };

    return (
        <div className='fixed bg-[rgba(0,0,0,0.5)] w-full top-0 left-0 h-full backdrop-blur-sm z-40'>
            <div className='flex justify-center items-center h-full'>
                <div className={`transition-all duration-300 ease-in-out py-10 px-10 rounded-xl ${themeMode === 'dark' ? 'bg-[rgb(50,50,50)]' : 'bg-slate-50'} `}>
                    <h2 className='font-bold text-2xl mb-5'>Upload a video</h2>
                    <form className='flex gap-5' encType='multipart/form-data'>
                        <div>
                            <div className='flex flex-col gap-5 w-[290px]'>
                                <input type="text" name='title' value={formData.title} placeholder='Title' className='px-5 py-2 rounded-xl text-black border' onChange={handleChange} />
                                <textarea type="text" name='description' value={formData.description} placeholder='Description' className='px-5 py-2 h-[16rem] resize-none rounded-xl text-black border' onChange={handleChange} />
                            </div>
                            <div className='flex flex-col gap-5 w-[290px] mt-5'>
                                <select className='px-5 py-2 rounded-xl text-black border' name='categories' onChange={handleChange}>
                                    <option value=''>Select Category</option>
                                    {categories.length > 0 && categories.map((category, index) => (
                                        <option key={index} value={category._id}>{category}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div>
                            <div className='flex flex-col gap-5'>
                                <div className='relative'>
                                    <input
                                        type='text'
                                        name='trainer'
                                        value={formData.trainer}
                                        placeholder='Select Trainer'
                                        className='px-5 py-2 rounded-xl text-black w-full'
                                        onClick={() => setIsModalOpen(true)}
                                        readOnly
                                    />
                                    {isModalOpen && (
                                        <TrainerSearchModal
                                            trainers={trainers}
                                            onTrainerSelect={handleTrainerSelect}
                                            onClose={() => setIsModalOpen(false)}
                                        />
                                    )}
                                </div>
                                <div className='flex gap-2 w-full justify-between'>
                                    <label className="block text-lg font-bold mr-4" htmlFor="quickLink">
                                        Serve as Quick Link?
                                    </label>
                                    <button
                                        type='button'
                                        className={`w-16 h-8 rounded-full transition-all ease-in-out duration-300 p-1 ${formData.quickLink ? 'bg-[rgb(224,92,19)]' : 'bg-gray-500'
                                            }`}
                                        onClick={() =>
                                            handleInputChange({
                                                target: { name: 'quickLink', value: !formData.quickLink },
                                            })
                                        }>
                                        <div
                                            className={`w-7 h-6 rounded-full shadow-md transition-all z-10 ease-in-out duration-300 ${formData.quickLink ? 'transform translate-x-7' : ''
                                                } bg-white border-2 border-gray-300`}
                                        ></div>
                                    </button>
                                </div>
                                <input type="file" placeholder='video' name='video' className='px-5 py-2 rounded-xl bg-orange-500' onChange={handleVideoChange} accept="video/*" />
                                <div className='flex flex-col w-[290px]'>
                                    <label className="block text-lg font-bold mb-2" htmlFor="thumbnail">
                                        Select a new thumbnail*
                                    </label>
                                    {selectedImage ?
                                        <>  {selectedImage && (
                                            <div className='relative'>
                                                <div className='cursor-pointer absolute right-0 px-2 shadow-xl bg-red-500 transition-all ease-in-out duration-300 text-2xl hover:scale-105 text-white rounded-full'
                                                    onClick={handleImageDeselect}
                                                >
                                                    X                                        </div>
                                                <img
                                                    src={URL.createObjectURL(selectedImage)}
                                                    alt="Selected"
                                                    className='w-full max-h-[150px]'
                                                />
                                                <p className='mt-4 font-semibold text-center'>Selected thumbnail: <br />{selectedImage.name}</p>
                                            </div>
                                        )}</> :
                                        <label
                                            className={`transition-all ease-in-out duration-300 
                                            w-full h-10 py-5 mt-5 flex justify-center items-center rounded-2xl border-2 border-dashed hover:rounded-xl cursor-pointer ${selectedImage ? 'hidden' : ''
                                                }`}
                                            htmlFor="thumbnail"
                                        >
                                            <p className="text-lg">Select new thumbnail</p>
                                        </label>
                                    }
                                    <input
                                        type="file"
                                        accept="image/*"
                                        className='hidden'
                                        onChange={handleThumbnailChange}
                                        name="thumbnail"
                                        id="thumbnail"
                                        ref={fileInputRef}
                                    />
                                </div>
                            </div>
                            <div className='flex gap-5 mt-10 justify-end items-center'>
                                {loading === true ? <p className={`transition-all ease-in-out duration-300 font-semibold 
                            text-green-500 p-3 rounded-2xl ${themeMode === 'dark' ? 'bg-green-950' : 'bg-green-200'}`}>Uploading please wait...</p>
                                    : <Button text={"Upload"} onClick={handleSubmit} />
                                }
                                <Button onClick={handleCancel} text={"Cancel"} />
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    )
}

export default AddVideoForm