import React, { useState, useEffect, useContext } from 'react';
import { myContext } from '../../../../../Context';
import axios from 'axios';
import Button from '../../../../Static/Button';
import toast from 'react-hot-toast';
import CertModal from './CertModal';
import TrainerProfileDetails from './TrainerProfileDetails';

export default function TrainerProfile({ handleItemClick, token }) {
    const { themeMode } = useContext(myContext);
    const profile = localStorage.getItem('trainerId');
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [selectedCertificate, setSelectedCertificate] = useState(null);
    const [confirmModal, setConfirmModal] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        age: 0,
        experience: 0,
        address: '',
        category: '',
        subcategory: '',
        gender: '',
        email: '',
        phoneNumber: '',
        dob: '',
        photoURL: '',
        password: '' || null,
        permissions: '' || false,
    });
    const [categories, setCategories] = useState([]);
    const [subcategories, setSubcategories] = useState([]);

    const getCategories = async () => {
        try {
            const res = await axios.get(process.env.REACT_APP_ENDPOINT + '/trainer/categories', {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.status === 200) {
                setCategories(res.data.categories);
                setSubcategories(res.data.subcategories);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;

        const inputValue = type === 'checkbox' ? checked : value;

        setFormData({
            ...formData,
            [name]: inputValue
        });
    };

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const [isModalOpen, setModalOpen] = useState(false);

    const openModal = (certificate) => {
        setModalOpen(true);
        setSelectedCertificate(certificate);
    };

    const closeModal = () => {
        setModalOpen(false);
        setSelectedCertificate(null);
    };

    const getTrainer = async () => {
        try {
            const res = await axios.get(process.env.REACT_APP_ENDPOINT + '/auth/getTrainer/' + profile, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.status === 200) {
                setFormData(res.data.trainerDetails);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleModalClick = (id) => {
        deleteTrainer(id);
        setConfirmModal(!confirmModal);
    };

    const deleteTrainer = async (id) => {
        try {
            const res = await axios.delete(process.env.REACT_APP_ENDPOINT + '/auth/deleteTrainer/' + id, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.status === 200) {
                toast.success('Trainer deleted!');
                handleItemClick('Trainers List');
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleEditClick = async () => {
        try {
            const res = await axios.put(process.env.REACT_APP_ENDPOINT + '/auth/updateTrainer/' + profile, formData, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.status === 200) {
                toast.success('Trainer account edited!');
                setEditMode(false);
            }

        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        getTrainer();
        getCategories();
    }, []);

    return (
        <div className={`w-full py-24 px-7 h-full`}>
            <TrainerProfileDetails editMode={editMode} formData={formData} handleInputChange={handleInputChange}
                categories={categories} subcategories={subcategories}
                setEditMode={setEditMode} handleEditClick={handleEditClick}
                handleModalClick={handleModalClick} openModal={openModal}
                confirmModal={confirmModal} setConfirmModal={setConfirmModal}
                handleItemClick={handleItemClick} setPageNumber={setPageNumber} setNumPages={setNumPages}
                setSelectedCertificate={setSelectedCertificate}
                profile={profile} token={token}

            />
            <CertModal isModalOpen={isModalOpen} closeModal={closeModal} pageNumber={pageNumber} setPageNumber={setPageNumber}
                numPages={numPages} onDocumentLoadSuccess={onDocumentLoadSuccess} selectedCertificate={selectedCertificate}
            />
            {confirmModal && (
                <div className='fixed bg-[rgba(0,0,0,0.5)] w-full top-0 left-0 h-full backdrop-blur-sm z-50'>
                    <div className='flex justify-center items-center h-full'>
                        <div className={`transition-all ease-in-out duration-300 py-5 px-10 rounded-xl ${themeMode === 'dark' ? 'bg-[rgb(50,50,50)] text-white' : 'bg-slate-50 text-black'} `}>
                            <h2 className='font-bold text-2xl mb-5'>Confirm delete?</h2>
                            <h3 className='text-lg'>This action cannot be undone, are you sure?</h3>
                            <div className='flex gap-5 mt-10 justify-end'>
                                <Button onClick={() => handleModalClick(profile)} text={"Confirm"} />
                                <Button onClick={() => setConfirmModal(false)} text={"Cancel"} />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
