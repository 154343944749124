import React from 'react'
import { myContext } from '../../../../../Context'
import axios from 'axios'
import Button from '../../../../Static/Button';
import { MdEdit, MdDeleteForever } from 'react-icons/md';
import { IoArrowBack, IoArrowForward } from "react-icons/io5";
import ProductForm from './ProductForm';
import { toast } from 'react-hot-toast';
import { VscLoading } from 'react-icons/vsc';

export default function ProductDetails({ token, handleItemClick }) {
    const { themeMode } = React.useContext(myContext);
    const productId = localStorage.getItem('productId');
    const [product, setProduct] = React.useState({})
    const [editMode, setEditMode] = React.useState({ show: false, id: '' });
    const [formData, setFormData] = React.useState({
        name: '',
        description: '',
        price: 0,
        discountedPrice: 0,
        quantity: 0,
        category: '',
        subcategory: '',
        isActive: '',
        images: [],
    });
    const fileInputRef = React.useRef();
    const [selectedFiles, setSelectedFiles] = React.useState([]);
    const [confirmModal, setConfirmModal] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const handleImageChange = (e) => {
        setFormData({ ...formData, images: e.target.files });
        const files = e.target.files;
        const fileNames = Array.from(files).map((file) => file.name);
        setSelectedFiles(fileNames);
    };

    const getProduct = async () => {
        setLoading(true)
        try {
            const res = await axios.get(process.env.REACT_APP_ENDPOINT + '/shop/product/' + productId, {
                withCredentials: true,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.status === 200) {
                setProduct(res.data);
                setFormData({
                    name: res.data.name,
                    description: res.data.description,
                    price: res.data.price,
                    discountedPrice: res.data.discountedPrice,
                    quantity: res.data.quantity,
                    category: res.data.category,
                    subcategory: res.data.subcategory,
                    isActive: res.data.isActive,
                    images: res.data.images,
                });
                setLoading(false);
            }
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    }

    React.useEffect(() => {
        getProduct();
    }, [])

    const [currentImageIndex, setCurrentImageIndex] = React.useState(0);

    const nextImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % product.images.length);
    };

    const prevImage = () => {
        setCurrentImageIndex((prevIndex) =>
            prevIndex === 0 ? product.images.length - 1 : prevIndex - 1
        );
    };

    const handleEditClick = () => {
        getProduct();
        setEditMode({ show: true, id: product._id });
    }

    const handleEdit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const newFormData = new FormData();
        newFormData.append('name', formData.name);
        newFormData.append('description', formData.description);
        newFormData.append('price', formData.price);
        newFormData.append('discountedPrice', formData.discountedPrice);
        newFormData.append('quantity', formData.quantity);
        newFormData.append('category', formData.category);
        newFormData.append('subcategory', formData.subcategory);
        newFormData.append('isActive', formData.isActive);
        for (let i = 0; i < formData.images.length; i++) {
            newFormData.append('images', formData.images[i]);
        }
        try {
            const res = await axios.put(process.env.REACT_APP_ENDPOINT + '/shop/editProduct/' + product._id, newFormData, {
                withCredentials: true,
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.status === 200) {
                toast.success('Product Updated Successfully');
                getProduct();
                setEditMode({ show: false, id: '' });
                setLoading(false);
            }
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    }

    const handleDelete = async () => {
        setLoading(true);
        try {
            const res = await axios.delete(process.env.REACT_APP_ENDPOINT + '/shop/deleteProduct/' + product._id, {
                withCredentials: true,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.status === 200) {
                toast.success('Product Deleted Successfully');
                getProduct();
                setEditMode({ show: false, id: '' });
                setLoading(false);
            }
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    }

    return (
        <div className='w-full h-screen py-24 px-7'>
            {
                loading === true ? <div className={`${themeMode === "light" ? "bg-white" : "bg-[rgb(50,50,50)]"} h-screen w-full overflow-hidden`}>
                    <p className="flex justify-center items-center text-orange-500 h-full animate-spin text-[4rem]"><VscLoading /></p>
                </div> :
                    <div className={`transition-all flex gap-10 px-10 justify-start ease-in-out duration-300 py-10 text-lg rounded-2xl ${themeMode === 'dark' ? 'bg-[rgb(30,30,30)] text-white' : 'bg-slate-50'} text-black`}>
                        <div className='flex flex-col gap-5 w-[600px] relative'>
                            <img
                                src={product.images && product.images[currentImageIndex].url}
                                alt={product.name}
                                className='w-full h-full rounded-2xl shadow-lg'
                            />
                            <div className='absolute top-1/2 left-0 transform -translate-y-1/2' >
                                <Button icon={<IoArrowBack />} onClick={prevImage} />
                            </div>

                            <div className='absolute top-1/2 right-0 transform -translate-y-1/2'  >
                                <Button icon={<IoArrowForward />} onClick={nextImage} />
                            </div>
                        </div>
                        <div className='flex justify-between w-full'>
                            <div className='flex flex-col'>
                                <p className={`px-1 font-bold text-xl ${product.quantity !== 0 ? 'text-green-500' : 'text-red-500'}`}>{product.quantity !== 0 ? 'In Stock' : 'Out of Stock'}</p>
                                <p className='text-2xl mt-5'>{product.name}</p>
                                <p className='mt-5'>Price - ₹<span className='line-through ml-1'>{product.price}</span></p>
                                <p className='mt-1'>DC Price - ₹{product.discountedPrice}</p>
                                <p className='mt-5 w-[500px] whitespace-normal break-words'>Desc - {product.description}</p>
                                <p className='mt-5'>Category - {product.category}</p>
                                <p className='mt-5'>Sub Category - {product.subCategory}</p>
                                <p className='mt-5'>Active? - {product.isActive ? 'Active' : 'Inactive'}</p>
                            </div>
                            <div className='flex h-12 gap-3'>
                                <Button icon={<IoArrowBack />} onClick={() => handleItemClick('Products')} />
                                <Button icon={<MdEdit />} onClick={() => handleEditClick(product._id)} />
                                <Button icon={<MdDeleteForever />} onClick={() => setConfirmModal(!confirmModal)} />
                            </div>
                        </div>
                    </div>
            }
            {confirmModal && (
                <div className='fixed bg-[rgba(0,0,0,0.5)] w-full top-0 left-0 h-full backdrop-blur-sm z-50'>
                    <div className='flex justify-center items-center h-full'>
                        <div className={`py-5 px-10 rounded-xl ${themeMode === 'dark' ? 'bg-[rgb(50,50,50)] text-white' : 'bg-slate-50 text-black'} `}>
                            <h2 className='font-bold text-2xl mb-5'>Confirm delete?</h2>
                            <h3 className='text-lg'>This action cannot be undone, are you sure?</h3>
                            <div className='flex gap-5 mt-10 justify-end'>
                                {
                                    loading === true ? (
                                        <>
                                            <Button disabled={true} text={"Please wait..."} />
                                            <Button disabled={true} text={"Cancel"} />
                                        </>
                                    ) : (
                                        <>
                                            <Button onClick={() => handleDelete(product._id)} text={"Confirm"} />
                                            <Button onClick={() => setConfirmModal(false)} text={"Cancel"} />
                                        </>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {
                editMode.show && <ProductForm token={token} product={product}
                    editMode={editMode} setEditMode={setEditMode}
                    formData={formData} setFormData={setFormData}
                    fileInputRef={fileInputRef} handleImageChange={handleImageChange}
                    handleSubmit={handleEdit} selectedFiles={selectedFiles}
                    loading={loading} setConfirmModal={setConfirmModal}
                    text='Edit Product'
                />
            }

        </div>
    )
}

