import React, { useContext, useState, useEffect } from 'react'
import { IoArrowBack } from "react-icons/io5";
import { MdCancel, MdDeleteForever, MdEdit, MdSaveAlt } from "react-icons/md";
import { myContext } from '../../../../../Context';
import pfp from '../../../../../assets/images/Default_pfp.svg.png';
import Button from '../../../../Static/Button';
import moment from 'moment';
import axios from 'axios';
import Pagination from '../../../../Static/Pagination';
import { FaPlay } from "react-icons/fa";
import TrainerEditVideo from './TrainerEditVideo';
import toast from 'react-hot-toast';
import { VscLoading } from "react-icons/vsc";

function TrainerProfileDetails({
    handleItemClick,
    editMode,
    setEditMode,
    formData,
    categories,
    subcategories,
    handleInputChange,
    handleEditClick,
    confirmModal,
    setConfirmModal,
    token,
    profile }) {

    const { themeMode } = useContext(myContext);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const videosPerPage = 7;
    const [Videos, setVideos] = useState([]);
    const [editVideoModal, seteditVideoModal] = useState({
        show: false,
        id: null,
    });
    const [videoData, setvideoData] = useState({
        title: '',
        description: '',
        trainer: '',
        categories: '',
        isQuickLink: '',
        video: null,
        thumbnail: null,
    });
    const [deleteModal, setDeleteModal] = useState({
        show: false,
        id: null,
    });

    const indexOfLastVideo = currentPage * videosPerPage;
    const indexOfFirstVideo = indexOfLastVideo - videosPerPage;
    const currentVideos = Videos.slice(indexOfFirstVideo, indexOfLastVideo);

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(Videos.length / videosPerPage); i++) {
        pageNumbers.push(i);
    }

    const playVideo = (videoURL) => {
        setSelectedVideo(videoURL);
    };

    const getVideos = async () => {
        setLoading(true);
        try {
            const res = await axios.get(process.env.REACT_APP_ENDPOINT + `/video/trainerVideos/${profile}`, {
                withCredentials: true,
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (res.status === 200) {
                setVideos(res.data.videos);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    };

    useEffect(() => {
        getVideos()
    }, []);

    const editVideo = async (editModal) => {
        const data = new FormData();
        data.append('title', videoData.title);
        data.append('description', videoData.description);
        data.append('categories', videoData.categories);
        data.append('isQuickLink', videoData.isQuickLink);
        if (videoData.video !== null || undefined) data.append('video', videoData.video);
        if (videoData.thumbnail !== null || undefined) data.append('thumbnail', videoData.thumbnail);

        setLoading(true);
        try {
            const res = await axios.put(`${process.env.REACT_APP_ENDPOINT}/video/editVideo/${editModal.id}`, data, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.status === 200) {
                toast.success('Video Updated!');
                seteditVideoModal(false);
                getVideos();
                setLoading(false);
            }
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    }

    const deleteVideo = async (videoId, trainerId) => {
        try {
            const res = await axios.delete(
                `${process.env.REACT_APP_ENDPOINT}/video/deleteVideo`,
                {
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    data: {
                        videoId: videoId,
                        trainerId: trainerId,
                    },
                }
            );

            if (res.status === 200) {
                toast.success('Video Deleted!');
                setDeleteModal({ show: false, id: null })
                getVideos();
            }
        }
        catch (error) {
            console.error(error);
        }
    }

    return (
        <div className={`transition-all ease-in-out duration-300 h-full px-9 py-7 rounded-2xl ${themeMode === 'dark' ? 'bg-[rgb(50,50,50)] text-white' : 'bg-slate-50'}`}>
            {
                loading ? <div className={`${themeMode === "light" ? "bg-white" : "bg-[rgb(50,50,50)]"} h-screen w-full overflow-hidden`}>
                    <p className="flex justify-center items-center text-orange-500 h-full animate-spin text-[4rem]"><VscLoading /></p>
                </div> :
                    <>
                        <div className='flex justify-between'>
                            <div className='text-3xl font-semibold'>Profile</div>
                            <div className='flex gap-3 text-xl'>
                                <Button text={<IoArrowBack />} onClick={() => handleItemClick('Trainers List')} />
                                <Button
                                    icon={editMode === true ? <MdCancel /> : <MdEdit />}
                                    onClick={() => setEditMode(!editMode)}
                                />
                                <Button
                                    icon={<MdDeleteForever />}
                                    onClick={() => setConfirmModal(!confirmModal)}
                                />
                            </div>
                        </div>
                        <div className='flex gap-3 mt-10 items-center'>
                            <div className='flex flex-col gap-5 justify-center items-center'>
                                <img src={formData.photoURL ? formData.photoURL : pfp} className='w-36 h-36 rounded-full' />
                                <p className='text-xl font-semibold'>{formData.permissions === true ? 'Trainer Admin' : ''}</p>
                                <p className='text-xl font-semibold'>XP Points: {formData.xpPoints}</p>
                            </div>
                            <div className='flex flex-col px-20'>
                                <div className='flex'>
                                    <div className='flex flex-col gap-3 mr-10'>
                                        <p className='text-lg font-semibold'>Name:{" "}
                                            {editMode ? (
                                                <div className='flex flex-col mt-2'>
                                                    <input
                                                        type='text'
                                                        name='firstName'
                                                        value={formData.firstName}
                                                        onChange={handleInputChange}
                                                        className={`transition-all duration-300 border ease-in-out rounded-xl px-3 py-1 ${themeMode === 'dark' ? 'bg-[rgb(50,50,50)] text-white' : 'bg-white'}`}
                                                        placeholder='First Name'
                                                    />
                                                    <input
                                                        type='text'
                                                        name='lastName'
                                                        value={formData.lastName}
                                                        onChange={handleInputChange}
                                                        className={`transition-all duration-300 border mt-5 ease-in-out rounded-xl px-3 py-1 ${themeMode === 'dark' ? 'bg-[rgb(50,50,50)] text-white' : 'bg-white'}`}
                                                        placeholder='Last Name'
                                                    />
                                                </div>
                                            ) : (
                                                `${formData.firstName} ${formData.lastName}`
                                            )}
                                        </p>
                                        <p className='text-lg font-semibold'>Email:{" "}
                                            {editMode ? (
                                                <input
                                                    type='text'
                                                    name='email'
                                                    value={formData.email}
                                                    onChange={handleInputChange}
                                                    className={`transition-all duration-300 border ease-in-out rounded-xl px-3 py-1 ${themeMode === 'dark' ? 'bg-[rgb(50,50,50)] text-white' : 'bg-white'}`}
                                                    placeholder='Email'
                                                />
                                            ) : (
                                                formData.email
                                            )}
                                        </p>
                                        <p className='text-lg font-semibold'>Phone Number:{" "}
                                            {editMode ? (
                                                <input
                                                    type='text'
                                                    name='phoneNumber'
                                                    value={formData.phoneNumber}
                                                    onChange={handleInputChange}
                                                    className={`transition-all duration-300 border ease-in-out rounded-xl px-3 py-1 ${themeMode === 'dark' ? 'bg-[rgb(50,50,50)] text-white' : 'bg-white'}`}
                                                    placeholder='Phone Number'
                                                />
                                            ) : (
                                                formData.phoneNumber
                                            )}
                                        </p>
                                        <p className='text-lg font-semibold'>Address:{" "}
                                            {editMode ? (
                                                <input
                                                    type='text'
                                                    name='address'
                                                    value={formData.address}
                                                    onChange={handleInputChange}
                                                    className={`transition-all duration-300 border ease-in-out rounded-xl px-3 py-1 ${themeMode === 'dark' ? 'bg-[rgb(50,50,50)] text-white' : 'bg-white'}`}
                                                    placeholder='Address'
                                                />
                                            ) : (
                                                formData.address
                                            )}
                                        </p>
                                    </div>
                                    <div className='flex flex-col gap-3'>
                                        <p className='text-lg font-semibold'>Gender:{" "}
                                            {editMode ? (
                                                <select
                                                    name='gender'
                                                    value={editMode ? formData.gender : formData.gender}
                                                    onChange={handleInputChange}
                                                    className={`transition-all duration-300 border ease-in-out rounded-xl px-3 py-1 ${themeMode === 'dark' ? 'bg-[rgb(50,50,50)] text-white' : 'bg-white'}`}
                                                >
                                                    <option value='male'>Male</option>
                                                    <option value='female'>Female</option>
                                                    <option value='other'>Other</option>
                                                </select>
                                            ) : (
                                                <span className='capitalize'>
                                                    {formData.gender}
                                                </span>
                                            )}
                                        </p>
                                        <p className='text-lg font-semibold'>
                                            Date of Birth:{" "}
                                            {editMode ? (
                                                <input
                                                    type='date'
                                                    name='dob'
                                                    value={formData.dob}
                                                    onChange={handleInputChange}
                                                    className={`transition-all duration-300 border ease-in-out rounded-xl px-3 py-1 ${themeMode === 'dark' ? 'bg-[rgb(50,50,50)] text-white' : 'bg-white'
                                                        }`}
                                                    placeholder='Date of Birth'
                                                />
                                            ) : (
                                                moment(formData.dob).format('MMMM DD, YYYY')
                                            )}
                                        </p>
                                        <p className='text-lg font-semibold'>Age:{" "}
                                            {editMode ? (
                                                <input
                                                    type='text'
                                                    name='age'
                                                    value={formData.age}
                                                    onChange={handleInputChange}
                                                    className={`transition-all duration-300 border ease-in-out rounded-xl px-3 py-1 ${themeMode === 'dark' ? 'bg-[rgb(50,50,50)] text-white' : 'bg-white'}`}
                                                    placeholder='Age'
                                                />
                                            ) : (
                                                formData.age
                                            )} years
                                        </p>
                                        <p className='text-lg font-semibold'>Experience:{" "}
                                            {editMode ? (
                                                <input
                                                    type='text'
                                                    name='experience'
                                                    value={formData.experience}
                                                    onChange={handleInputChange}
                                                    className={`transition-all duration-300 border ease-in-out rounded-xl px-3 py-1 ${themeMode === 'dark' ? 'bg-[rgb(50,50,50)] text-white' : 'bg-white'}`}
                                                    placeholder='Experience'
                                                />
                                            ) : (
                                                formData.experience
                                            )} years
                                        </p>
                                    </div>
                                </div>
                                <hr className='mt-10 mb-8' />
                                <p className='text-lg font-semibold'>
                                    Category:{" "}
                                    {editMode ? (
                                        <select
                                            name='category'
                                            value={formData.category}
                                            onChange={handleInputChange}
                                            className={`transition-all duration-300 border ease-in-out rounded-xl px-3 py-1 ${themeMode === 'dark' ? 'bg-[rgb(50,50,50)] text-white' : 'bg-white'
                                                }`}
                                        >
                                            {categories.map((category) => (
                                                <option key={category} value={category}>
                                                    {category}
                                                </option>
                                            ))}
                                        </select>
                                    ) : (
                                        formData.category
                                    )}
                                </p>
                                <p className='text-lg font-semibold mt-2'>
                                    Sub-Category:{" "}
                                    {editMode ? (
                                        <select
                                            name='subcategory'
                                            value={formData.subcategory}
                                            onChange={handleInputChange}
                                            className={`transition-all duration-300 border ease-in-out rounded-xl mt-5 px-3 py-1 ${themeMode === 'dark' ? 'bg-[rgb(50,50,50)] text-white' : 'bg-white'
                                                }`}
                                        >
                                            {subcategories.map((subcategory) => (
                                                <option key={subcategory} value={subcategory}>
                                                    {subcategory}
                                                </option>
                                            ))}
                                        </select>
                                    ) : (
                                        formData.subcategory
                                    )}
                                </p>
                                {editMode ? (
                                    <p className='text-lg font-semibold mt-2'>
                                        Add password:{" "}
                                        <input
                                            type='password'
                                            name='password'
                                            value={formData.password}
                                            onChange={handleInputChange}
                                            className={`transition-all duration-300 border ease-in-out rounded-xl mt-5 px-3 py-1 ${themeMode === 'dark' ? 'bg-[rgb(50,50,50)] text-white' : 'bg-white'
                                                }`}
                                            placeholder='Password'
                                        />
                                    </p>
                                ) : null}
                                {editMode ? (
                                    <p className='text-lg font-semibold'>
                                        Give access:{" "}
                                        <input
                                            type='checkbox'
                                            name='permissions'
                                            checked={formData.permissions ? true : false}
                                            onChange={handleInputChange}
                                            className={`transition-all duration-300 border ease-in-out w-5 h-5 rounded-xl mt-5 px-3 py-1 ${themeMode === 'dark' ? 'bg-[rgb(50,50,50)] text-white' : 'bg-white'
                                                }`}
                                        />
                                    </p>
                                ) : null}
                            </div>
                        </div>
                        {editMode === true &&
                            <div className='flex justify-end gap-3 mt-5'>
                                <Button icon={<MdSaveAlt />} text='Save' onClick={handleEditClick} />
                                <Button icon={<MdCancel />} text='Cancel' onClick={() => setEditMode(false)} />
                            </div>
                        }
                        <hr className='my-10' />
                        <div className='flex flex-col w-full justify-start gap-5'>
                            {loading ? <div className={`${themeMode === "light" ? "bg-white" : "bg-[rgb(50,50,50)]"} h-screen w-full overflow-hidden`}>
                                <p className="flex justify-center items-center text-orange-500 h-full animate-spin text-[4rem]"><VscLoading /></p>
                            </div> :
                                currentVideos.length > 0 ?
                                    currentVideos.map((video, index) => (
                                        <div key={index} className={`flex gap-10 p-5 rounded-lg items-start w-full ${themeMode === "light" ? "bg-white" : "bg-[rgb(60,60,60)]"}`}>
                                            {selectedVideo === video.videoURL ? (
                                                <video
                                                    className='rounded-lg w-[300px] cursor-pointer'
                                                    src={video.videoURL}
                                                    controls
                                                    width={400}
                                                />
                                            ) : (
                                                <div
                                                    className='rounded-lg max-w-[300px] cursor-pointer relative'
                                                    onClick={() => playVideo(video.videoURL)}
                                                >
                                                    <div className='absolute z-10 w-full flex justify-center items-center h-full'>
                                                        <FaPlay className=' text-gray-300 text-5xl' />
                                                    </div>
                                                    <div className='relative'>
                                                        <img className='w-full h-full rounded-xl' src={video.thumbnailURL} alt="Thumbnail" />
                                                    </div>
                                                </div>
                                            )}
                                            <div className='flex justify-between items-start w-full'>
                                                <div className='flex flex-col gap-1 w-2/3'>
                                                    <p className='text-lg font-bold'>{video.title}</p>
                                                    <p className=''>{video.categories}</p>
                                                    <p className=' overflow-hidden overflow-ellipsis max-w-[600px] whitespace-normal break-words'>
                                                        {video.description}
                                                    </p>
                                                </div>
                                                <div className='flex flex-col gap-1 mr-5'>
                                                    <p className=''>
                                                        {video.approved === true ? 'Approved' : 'Pending..'}
                                                    </p>
                                                    <p className=''>Views: {video.views}</p>
                                                </div>
                                                <div className='flex gap-3'>
                                                    <div className='text-2xl'>
                                                        <Button text={<MdEdit />} onClick={() => seteditVideoModal(
                                                            {
                                                                show: true,
                                                                id: video._id,
                                                            }
                                                        )} />
                                                    </div>
                                                    <Button icon={<MdDeleteForever />} onClick={() => setDeleteModal({ show: true, id: '' })} />
                                                </div>
                                            </div>
                                            {deleteModal.show === true && (
                                                <div className='fixed bg-[rgba(0,0,0,0.5)] w-full top-0 left-0 h-full backdrop-blur-sm z-50'>
                                                    <div className='flex justify-center items-center h-full'>
                                                        <div className={`transition-all ease-in-out duration-300 py-5 px-10 rounded-xl ${themeMode === 'dark' ? 'bg-[rgb(50,50,50)] text-white' : 'bg-slate-50 text-black'} `}>
                                                            <h2 className='font-bold text-2xl mb-5'>Confirm delete?</h2>
                                                            <h3 className='text-lg'>This action cannot be undone, are you sure?</h3>
                                                            <div className='flex gap-5 mt-10 justify-end'>
                                                                <Button text='Yes' onClick={() => deleteVideo(video._id, profile)} />
                                                                <Button text='No' onClick={() => setDeleteModal({ show: false, id: profile })} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )) : <p className='text-center text-xl py-10'>No videos found</p>
                            }
                            <div className='flex w-full items-center justify-end py-2 gap-5'>
                                Showing {indexOfFirstVideo + 1}-{Math.min(indexOfLastVideo, Videos.length)} of {Videos.length} videos
                                <Pagination pageNumbers={pageNumbers} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                            </div>
                        </div>
                    </>
            }
            {editVideoModal.show === true && <TrainerEditVideo editVideoModal={editVideoModal} seteditVideoModal={seteditVideoModal}
                editVideo={editVideo} token={token} loading={loading} setLoading={setLoading}
                formData={videoData} setFormData={setvideoData}
            />}
        </div>
    )
}

export default TrainerProfileDetails