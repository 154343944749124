import React, { useContext } from "react";
import axios from "axios";
import logo from "../../../assets/images/logo.png";
import darkLogo from "../../../assets/images/darkLogo.png";
import pfp from "../../../assets/images/Default_pfp.svg.png";
import { myContext } from "../../../Context";
import { FaUser } from "react-icons/fa";
import { IoLogOut } from "react-icons/io5";
import ThemeToggler from "../../Static/ThemeToggler";

export default function Header({ handleItemClick, profile }) {
    const token = localStorage.getItem("jwtToken");
    const { themeMode } = useContext(myContext);

    const handleLogout = async () => {
        try {
            const res = await axios.get(process.env.REACT_APP_ENDPOINT + '/auth/logout', {
                withCredentials: true,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.status === 200) {
                localStorage.removeItem("jwtToken");
                localStorage.removeItem("jwtTokenExpiration")
                window.location.href = "/";
            }

        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div className={`transition-all duration-300 ease-in-out fixed top-0 w-full shadow-md z-20 ${themeMode === 'dark' ? 'bg-[rgb(24,26,27)]' : 'bg-white'}`}>
            <div className="flex justify-between items-center p-1 pl-4 w-full">
                <div className="flex">
                    {themeMode === "dark" ? (
                        <img src={darkLogo} className="h-24 w-full" alt="Logo" />
                    ) : (
                        <img src={logo} className="h-24 w-full" alt="Logo" />
                    )}
                </div>
                <h3 className="bg-[rgb(230,120,19)] px-24 py-3 text-white text-lg font-bold rounded-xl">Gym App - Admin Dashboard</h3>
                <div className="flex gap-5">
                    <ThemeToggler />
                    <div className="group rounded-full cursor-pointer flex items-center relative pr-5">
                        <div className="bg-[rgb(230,120,19)] px-5 py-2 rounded-full text-white flex gap-2 items-center z-20">
                            <img src={pfp} className="w-10 h-10" />

                            <p className="text-lg cursor-pointer">{profile}</p>
                        </div>
                        <div className="transition-all duration-300 ease-in-out absolute bg-gray-200 rounded-3xl shadow-xl p-5 pt-14 w-full opacity-0 top-1 invisible group-hover:visible group-hover:top-4 group-hover:opacity-100">
                            <div className="flex flex-col">
                                <ul className=" list-none text-lg cursor-pointer">
                                    <li className="flex items-center gap-2 transition-all ease-in-out duration-100 mb-2 hover:scale-105" onClick={() => handleItemClick('Profile')}><FaUser />Profile</li>
                                    <hr className="my-2 border-orange-500 opacity-50" />
                                    <li className="flex items-center gap-2 transition-all ease-in-out duration-100 hover:scale-105" onClick={handleLogout}><IoLogOut />Logout</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}
