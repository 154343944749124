import React from 'react'
import { myContext } from '../../../../../Context'
import axios from 'axios'
import Button from '../../../../Static/Button';

function ProductForm({ text, formData, setFormData, editMode, setEditMode, selectedFiles, handleSubmit, handleImageChange, fileInputRef, loading, setConfirmModal }) {
    const { themeMode } = React.useContext(myContext);

    return (
        <div className='fixed bg-[rgba(0,0,0,0.5)] w-full top-0 left-0 h-full backdrop-blur-sm z-40'>
            <div className='flex justify-center items-center h-full'>
                <div className={`px-10 w-[1000px] py-5 shadow-2xl rounded-2xl ${themeMode === 'dark' ? 'bg-[rgb(50,50,50)] text-white' : 'bg-slate-100'}`}>
                    <form onSubmit={handleSubmit} className='w-full'>
                        <div className='flex flex-row justify-between items-center mb-5'>
                            <h1 className='text-2xl font-semibold'>{text}</h1>
                        </div>

                        <div className='flex'>
                            <div className='flex flex-col justify-between items-center w-full gap-5'>
                                <div className='flex flex-col gap-2 w-full'>
                                    <label htmlFor='Name' className='text-lg font-medium'>Product Name</label>
                                    <input type='text' name='Name' id='Name' value={formData.name} onChange={(e) => setFormData({ ...formData, name: e.target.value })} className='mt-2 p-2 rounded-md outline-none text-black' />
                                </div>
                                <div className='flex flex-col gap-2 w-full'>
                                    <label htmlFor='description' className='text-lg font-medium'>Product Description</label>
                                    <textarea name='description' value={formData.description} onChange={(e) => setFormData({ ...formData, description: e.target.value })} className='h-[300px] text-sm mt-2 p-2 rounded-md outline-none text-black' />
                                </div>
                            </div>
                            <div className='flex flex-col items-center w-full gap-5'>
                                <div className='flex justify-evenly items-center w-full'>
                                    <div className='flex flex-col gap-2'>
                                        <label htmlFor='price' className='text-lg font-medium'>Price</label>
                                        <input type='number' name='price' id='price' value={formData.price} onChange={(e) => setFormData({ ...formData, price: e.target.value })} className='w-[90px] mt-2 p-2 rounded-md outline-none text-black' />
                                    </div>
                                    <div className='flex flex-col gap-2'>
                                        <label htmlFor='discountedPrice' className='text-lg font-medium'>Discount Price</label>
                                        <input type='number' name='discountedPrice' value={formData.discountedPrice} id='discountedPrice' onChange={(e) => setFormData({ ...formData, discountedPrice: e.target.value })} className='w-[90px] mt-2 p-2 rounded-md outline-none text-black' />
                                    </div>
                                    <div className='flex flex-col gap-2'>
                                        <label htmlFor='quantity' className='text-lg font-medium'>Quantity</label>
                                        <input type='number' name='quantity' value={formData.quantity} id='quantity' onChange={(e) => setFormData({ ...formData, quantity: e.target.value })} className='w-[90px] mt-2 p-2 rounded-md outline-none text-black' />
                                    </div>
                                </div>
                                <div className='flex items-center gap-5'>
                                    <div className='flex flex-col gap-2'>
                                        <label htmlFor='category' className='text-lg font-medium'>Category</label>
                                        <select
                                            name='category'
                                            value={formData.category}
                                            id='category'
                                            onChange={(e) => setFormData({ ...formData, category: e.target.value })}
                                            className='mt-2 p-2 rounded-md outline-none text-black'>
                                            <option value=''>Select Category</option>
                                            <option value='ProteinShakes'>Protein Shakes</option>
                                            <option value='WorkoutSupplements'>Workout Supplements</option>
                                            <option value='GymAccessories'>Gym Accessories</option>
                                            <option value='FitnessApparel'>Fitness Apparel</option>
                                            <option value='FitnessEquipment'>Fitness Equipment</option>
                                            <option value='Hydration'>Hydration</option>
                                        </select>
                                    </div>
                                    <div className='flex flex-col gap-2'>
                                        <label htmlFor='subcategory' className='text-lg font-medium'>Sub Category</label>
                                        <select
                                            name='subcategory'
                                            value={formData.subcategory}
                                            id='subcategory'
                                            onChange={(e) => setFormData({ ...formData, subcategory: e.target.value })}
                                            className='mt-2 p-2 rounded-md outline-none text-black'>
                                            <option disabled value=''>Select a Category</option>
                                            {formData.category === 'ProteinShakes' && (
                                                <>
                                                    <option value='WheyProtein'>Whey Protein</option>
                                                    <option value='PlantBasedProtein'>Plant-Based Protein</option>
                                                    <option value='ProteinBars'>Protein Bars</option>
                                                    <option value='ProteinPowders'>Protein Powders</option>
                                                    <option value='ProteinSnacks'>Protein Snacks</option>
                                                </>
                                            )}
                                            {formData.category === 'WorkoutSupplements' && (
                                                <>
                                                    <option value='PreWorkout'>Pre-Workout</option>
                                                    <option value='PostWorkout'>Post-Workout</option>
                                                    <option value='BCAASupplements'>BCAA Supplements</option>
                                                    <option value='Creatine'>Creatine</option>
                                                    <option value='EnergyBoosters'>Energy Boosters</option>
                                                </>
                                            )}
                                            {formData.category === 'GymAccessories' && (
                                                <>
                                                    <option value='GymBags'>Gym Bags</option>
                                                    <option value='Gloves'>Gloves</option>
                                                    <option value='ResistanceBands'>Resistance Bands</option>
                                                    <option value='YogaMats'>Yoga Mats</option>
                                                    <option value='FitnessTrackers'>Fitness Trackers</option>
                                                </>
                                            )}
                                            {formData.category === 'FitnessApparel' && (
                                                <>
                                                    <option value='Activewear'>Activewear</option>
                                                    <option value='Shoes'>Shoes</option>
                                                    <option value='Headbands'>Headbands</option>
                                                    <option value='Socks'>Socks</option>
                                                    <option value='SportsBras'>Sports Bras</option>
                                                </>
                                            )}
                                            {formData.category === 'FitnessEquipment' && (
                                                <>
                                                    <option value='Treadmills'>Treadmills</option>
                                                    <option value='Weights'>Weights</option>
                                                    <option value='EllipticalTrainers'>Elliptical Trainers</option>
                                                    <option value='ExerciseBalls'>Exercise Balls</option>
                                                    <option value='ResistanceMachines'>Resistance Machines</option>
                                                </>
                                            )}
                                            {formData.category === 'Hydration' && (
                                                <>
                                                    <option value='WaterBottles'>Water Bottles</option>
                                                    <option value='HydrationPacks'>Hydration Packs</option>
                                                    <option value='WaterFilters'>Water Filters</option>
                                                    <option value='SportsDrinks'>Sports Drinks</option>
                                                    <option value='ElectrolyteSupplements'>Electrolyte Supplements</option>
                                                </>
                                            )}
                                        </select>
                                    </div>
                                </div>
                                <div className='flex w-full items-center justify-end pr-12'>
                                    <label htmlFor='isActive' className='text-lg font-medium mr-2'>Active?</label>
                                    <button
                                        value={formData.isActive}
                                        type='button'
                                        className={`w-16 h-8 rounded-full transition-all ease-in-out duration-300 p-1 ${formData.isActive ? 'bg-[rgb(224,92,19)]' : 'bg-gray-500'
                                            }`}
                                        onClick={() => setFormData({ ...formData, isActive: !formData.isActive })}
                                    >
                                        <div
                                            className={`w-7 h-6 rounded-full shadow-md transition-all ease-in-out duration-300 ${formData.isActive ? 'transform translate-x-7' : ''
                                                } bg-white border-2 border-gray-300`}
                                        ></div>
                                    </button>
                                </div>
                                <div className='flex flex-col w-[200px]'>
                                    <label
                                        className={`transition-all ease-in-out duration-300 
                                         w-full h-10 py-5 mt-5 flex justify-center items-center rounded-2xl border-2 border-gray-400 border-dashed hover:rounded-xl cursor-pointer`}
                                        htmlFor="images"
                                    >
                                        <p className="text-lg">Select images</p>
                                    </label>
                                    <input
                                        type="file"
                                        multiple
                                        accept="image/*"
                                        className='hidden'
                                        onChange={handleImageChange}
                                        name="images"
                                        id="images"
                                        ref={fileInputRef}
                                    />
                                    {selectedFiles ? selectedFiles.length > 0 && (
                                        <div className="mt-3">
                                            <p>Chosen files:</p>
                                            <ul>
                                                {selectedFiles.map((fileName, index) => (
                                                    <li key={index}>{fileName}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <div className='flex justify-end gap-5 w-full pb-2'>
                            {
                                loading === true ? (
                                    <>
                                        <Button disabled={true} text={"Please wait..."} />
                                        <Button disabled={true} text={"Cancel"} />
                                    </>
                                ) : (
                                    <>
                                        <Button text={text === 'Edit Product' ? 'Update' : 'Add'} type='submit' onClick={handleSubmit} />
                                        <Button text={"Cancel"} onClick={() => setConfirmModal(false)} />
                                    </>
                                )
                            }
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ProductForm