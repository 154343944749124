import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { myContext } from '../../../../../Context'
import toast from 'react-hot-toast'
import Button from '../../../../Static/Button'
import Autocomplete from 'react-google-autocomplete'

function AddTrainerForm({ token, getTrainers, setAddModal }) {
    const { themeMode } = useContext(myContext);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        experience: '',
        address: '',
        category: '',
        subcategory: '',
        gender: '',
        email: '',
        phoneNumber: '',
        password: '',
        dob: '',
        latitude: '',
        longitude: '',
        permissions: false,
    });
    const [categories, setCategories] = useState([]);
    const [subcategories, setSubcategories] = useState([]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handlePlaceSelect = (place) => {
        const { lat, lng } = place.geometry.location;

        setFormData((prevData) => ({
            ...prevData,
            latitude: lat(),
            longitude: lng(),
            address: place.formatted_address,
        }));
    };

    const getCategories = async () => {
        try {
            const res = await axios.get(process.env.REACT_APP_ENDPOINT + '/trainer/categories', {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            if (res.status === 200) {
                setCategories(res.data.categories);
                setSubcategories(res.data.subcategories);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getCategories();
    }, []);

    const onCreate = async (e) => {
        e.preventDefault();

        if (formData.firstName === '' || undefined || null) return toast.error('Please enter first name!');
        if (formData.lastName === '' || undefined || null) return toast.error('Please enter last name!');
        if (formData.email === '' || undefined || null) return toast.error('Please enter email!');
        if (formData.phoneNumber === '' || undefined || null) return toast.error('Please enter phone number!');
        if (formData.address === '' || undefined || null) return toast.error('Please enter address!');
        if (formData.category === '' || undefined || null) return toast.error('Please select category!');
        if (formData.subcategory === '' || undefined || null) return toast.error('Please select subcategory!');
        if (formData.latitude === '' || undefined || null) return toast.error('Please select location!');
        if (formData.longitude === '' || undefined || null) return toast.error('Please select location!');
        if (formData.dob === '' || undefined || null) return toast.error('Please select date of birth!');
        if (formData.experience === '' || undefined || null) return toast.error('Please enter experience!');
        if (formData.age === '' || undefined || null) return toast.error('Please enter age!');
        if (formData.gender === '' || undefined || null) return toast.error('Please select your gender!');

        try {
            const res = await axios.post(
                process.env.REACT_APP_ENDPOINT + '/auth/addTrainer',
                formData,
                {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (res.status === 200) {
                toast.success('Trainer added successfully!');
                setAddModal(!setAddModal);
                getTrainers();
            }
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div className='fixed bg-[rgba(0,0,0,0.5)] w-full top-0 left-0 h-full backdrop-blur-sm z-40'>
            <div className='flex justify-center items-center h-full'>
                <div className={`flex px-10 w-[500px] py-5 shadow-2xl rounded-2xl ${themeMode === 'dark' ? 'bg-[rgb(50,50,50)] text-white' : 'bg-slate-100'}`}>
                    <form onSubmit={onCreate} className='w-full'>
                        <div className='flex flex-row justify-between items-center'>
                            <h1 className='text-xl font-semibold'>Add Trainer</h1>
                            </div>
                        <div className='flex flex-col justify-between items-center mt-5'>
                            <div className='flex flex-row justify-between items-center w-full gap-5'>
                                <div>
                                    <label htmlFor='firstName' className=' font-medium'>First Name</label>
                                    <input type='text' name='firstName' id='firstName' onChange={handleChange} className='mt-2 p-2 rounded-md outline-none text-black' />
                                </div>
                                <div>
                                    <label htmlFor='lastName' className=' font-medium'>Last Name</label>
                                    <input type='text' name='lastName' id='lastName' onChange={handleChange} className='mt-2 p-2 rounded-md outline-none text-black' />
                                </div>
                            </div>
                            <div className='flex flex-row justify-between items-center w-full gap-5 mt-5'>
                                <div>
                                    <label htmlFor='email' className=' font-medium'>Email</label>
                                    <input type='email' name='email' id='email' onChange={handleChange} className='mt-2 p-2 rounded-md outline-none text-black' />
                                </div>
                                <div>
                                    <label htmlFor='phoneNumber' className=' font-medium'>Phone Number</label>
                                    <input type='text' name='phoneNumber' id='phoneNumber' onChange={handleChange} className='mt-2 p-2 rounded-md outline-none text-black' />
                                </div>
                            </div>
                            <div className='flex flex-row items-center w-full mt-5'>
                                <div className='flex gap-2 items-center'>
                                    <div className='flex flex-col gap-2'>
                                        <label htmlFor='gender' className=' font-medium'>Gender</label>
                                        <select
                                            name='gender'
                                            id='gender'
                                            value={formData.gender}
                                            onChange={handleChange}
                                            className='mt-2 p-2 w-[90px] rounded-md outline-none text-black'
                                        >
                                            <option value=''>Select</option>
                                            <option value='male'>Male</option>
                                            <option value='female'>Female</option>
                                            <option value='other'>Other</option>
                                        </select>
                                    </div>
                                    <div className='flex flex-col justify-center items-center'>
                                        <label htmlFor='experience' className=' font-medium'>Experience</label>
                                        <input type='number' inputMode='numeric' name='experience' id='experience' onChange={handleChange}
                                            className='mt-4 p-2 rounded-md w-[60px] outline-none text-black' />
                                    </div>
                                    <div className='flex flex-col justify-center items-center mr-3'>
                                        <label htmlFor='age' className=' font-medium'>Age</label>
                                        <input type='number' inputMode='numeric' name='age' id='age' onChange={handleChange}
                                            className='mt-4 p-2 rounded-md w-[60px] outline-none text-black' />
                                    </div>
                                    <div className='flex flex-col justify-center items-center'>
                                        <label htmlFor='dob' className=' font-medium'>Date of Birth</label>
                                        <input type='date' name='dob' id='dob' onChange={handleChange} className='mt-3 p-2 w-[140px] rounded-md outline-none text-black' />
                                    </div>
                                </div>
                            </div>
                            <div className='flex flex-row justify-between items-center w-full gap-5 mt-5'>
                                <div>
                                    <label htmlFor='location' className=' font-medium'>
                                        Location
                                    </label>
                                    <Autocomplete
                                        onPlaceSelected={(place) => {
                                            handlePlaceSelect(place);
                                        }}
                                        apiKey={process.env.REACT_APP_GMAPS_API}
                                        className='mt-2 p-2 rounded-md outline-none text-black'
                                        placeholder='Enter your location'
                                        options={{
                                            types: ["geocode", "establishment"],
                                        }}
                                    />
                                </div>
                                <div>
                                    <label htmlFor='address' className=' font-medium'>Address</label>
                                    <input
                                        type='text'
                                        name='address'
                                        id='address'
                                        value={formData.address}
                                        onChange={handleChange}
                                        className='mt-2 p-2 rounded-md outline-none text-black'
                                    />
                                </div>
                            </div>
                            <div className='flex gap-5 w-full'>
                                <div className='flex flex-col justify-between items-start mt-5'>
                                    <label htmlFor='category' className=' font-medium'>Category</label>
                                    <select name='category' id='category' onChange={handleChange} className='mt-2 p-2 w-[200px] rounded-md outline-none text-black'>
                                        <option value=''>Select</option>
                                        {categories.map((category, index) => (
                                            <option key={index} value={category}>{category}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className='flex flex-col justify-between items-start mt-3'>
                                    <label htmlFor='subcategory' className=' font-medium'>Subcategory</label>
                                    <select name='subcategory' id='subcategory' onChange={handleChange} className='mt-2 p-2 w-[200px] rounded-md outline-none text-black'>
                                        <option value=''>Select</option>
                                        {subcategories.map((subcategory, index) => (
                                            <option key={index} value={subcategory}>{subcategory}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className='flex w-full justify-between mt-5'>
                                <div className='w-full'>
                                    <label className="block mb-2 font-bold mr-4">
                                        Give permissions?
                                    </label>
                                    <button
                                        type='button'
                                        className={`w-16 h-8 rounded-full transition-all ease-in-out duration-300 p-1 ${formData.permissions ? 'bg-[rgb(224,92,19)]' : 'bg-gray-500'
                                            }`}
                                        onClick={() => setFormData({ ...formData, permissions: !formData.permissions })}>
                                        <div
                                            className={`w-7 h-6 rounded-full shadow-md transition-all z-10 ease-in-out duration-300 ${formData.permissions ? 'transform translate-x-7' : ''
                                                } bg-white border-2 border-gray-300`}
                                        ></div>
                                    </button>
                                </div>
                                <div>
                                    {
                                        formData.permissions === true ? (
                                            <div>
                                                <label htmlFor='password' className='text-lg font-medium'>Password</label>
                                                <input type='password' name='password' id='password' onChange={handleChange} className='mt-2 p-2 rounded-md outline-none text-black' />
                                            </div>
                                        ) : null}
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-row justify-end items-center pb-2 gap-5'>
                            <Button text='Add Trainer' onClick={onCreate} />
                            <Button text='Cancel' onClick={() => setAddModal(!setAddModal)} />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AddTrainerForm